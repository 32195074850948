import React from 'react'
import CustomerPageSEO from '../seo/CustomerPageSEO';
interface IProps {
    children: any;
    companyParam: string;
}
function SSOHoc(props: IProps) {
  return <>
       <CustomerPageSEO customerID={props.companyParam} />
         {props.children}
  </>
}

export default SSOHoc