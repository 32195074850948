import React, { useContext, useEffect } from 'react';
import { GameContext } from './GameContext';
import { CourseContext } from './CourseContext';

interface WebKitMessageHandlers {
    eventHandler: {
        postMessage: (message: any) => void;
    };
}

interface WebKit {
    messageHandlers: WebKitMessageHandlers;
}

declare global {
    interface Window {
        updateCurrentHole?: (holeNumber: number) => void;
        getCurrentHole?: () => number;
        webkit: WebKit;
    }
}

const ExposedMethods = () => {
    const courseContext = useContext(CourseContext);
    const gameContext = useContext(GameContext);

    // Function to be exposed for updating the current hole
    const externalUpdateCurrentHole = (holeNumber: number) => {
        courseContext.updateCurrentHole(holeNumber);
        //console.log(`Hole updated to ${holeNumber}`);
    };

    // Function to be exposed for getting the current hole
    const externalGetCurrentHole = () => {
        return courseContext.getCurrentHole().number;
    };

    const [loadedCourseContext, setLoadedCourseContext] = React.useState(false);

    useEffect(() => {

        if(courseContext.getAllHoles().length > 0 && !loadedCourseContext)
        {
        // Attach the functions to the window object
        window.updateCurrentHole = externalUpdateCurrentHole;
        window.getCurrentHole = externalGetCurrentHole;

        // Optionally send a test message if needed
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.eventHandler) {
            const currentHoleNumber = courseContext.getCurrentHole().number;
            const testMessage = `Test message from webview to React: hole ${currentHoleNumber}`;
            window.webkit.messageHandlers.eventHandler.postMessage(testMessage);
            //console.log(testMessage);
        }
    }

        // Clean-up function to remove the methods from the window object
        return () => {
            //delete window.updateCurrentHole;
            //delete window.getCurrentHole;
        };
    }, [courseContext.getAllHoles()]); // Empty dependency array to ensure this runs only once on mount and unmount

    return null; // This component doesn't render anything
};

export default ExposedMethods;
