import React, { Fragment, useEffect, useState } from 'react'
import './Carousel.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
// import swiper css
import 'swiper/swiper.scss';
import { Icons } from '../../../../atoms/Icons';
import StyleHelper from '../../../../../helpers/StyleHelper';


SwiperCore.use([Pagination]);

const testimonialsContent = [
    {
      description: (
        <Fragment>
          <p>
            Reviews coming soon
          </p>
        </Fragment>
      ),
      avatar: "avatar",
      name: "John Doe",
      title: "CEO, Company",
    }
  ]

interface ReviewResponse {
    success: boolean;
    message: string;
    reviews: Review[];
}
interface Review {
  type: number;
  name: string;
  tag: string;
  stars: number;
  comment: string;
  company: string;
  companyID: string;
  avatarIndex: number;
  color: string;
  date: string;
} 

function Carousel() {

    const [activeIndex, setActiveIndex] = useState(Math.floor(testimonialsContent.length / 2));
    const [swiperRef, setSwiperRef] = useState<SwiperCore | null>(null);
    const [loadingReviews, setLoadingReviews] = useState(true);
    const [reviews, setReviews] = useState<Review[]>([]);

    useEffect(() => {
 
      getReviews();
    }, []);

    const getReviews = async () => {
      await fetch(`${process.env.REACT_APP_API_URL}/PPG/GetFeedbackReviewsForWebsite`)
        .then(response => {
          if(!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data: ReviewResponse) => {
          setReviews(old => data.reviews);
          setLoadingReviews(false);
        })
        .catch(error => {
          console.log(error.message);
        });
    }

    const goToNextSlide = () => {
      if (swiperRef !== null) {
        swiperRef.slideNext();
      }
    };
  
    const goToPrevSlide = () => {
      if (swiperRef !== null) {
        swiperRef.slidePrev();
      }
    };

    const showCorrectAvatar = (review: Review) => {
      console.log(review);
      if(review.type == 1)
      {
        return <div className="avatar" style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/customers/${review.companyID}/images/create-game/sprites.png)`,
          backgroundSize: '400% 400%',
          backgroundPosition: StyleHelper.getProfilePictureAtIndex(review.avatarIndex),
          backgroundColor: review.color
        }}></div>
      }
      else
      {
        return <div className="avatar" style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/website/nopic.jpg)`,
        }}></div>
      }
    }
    
  return (
    <div className="content-block testimonials-wrap" style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/website/reviews-bg.jpg)`,
      
    }}>
      <div className='top-fader'></div>
      <div className='bottom-fader'></div>
    <div className="description">
      <h2>Don't take our word for it.</h2>
      <h3>READ REVIEWS FROM OWNERS AND PLAYERS ALIKE</h3>
    </div>

    <div className="testimonial-carousel-wrap">
    {/* <div className="testimonials-carousel">
      <button onClick={goToPrev}>&lt;</button>
      {testimonialsContent.map((testimonial, index) => (
        <div
          key={index}
          className={`testimonial-item ${index === activeIndex ? 'active' : ''}`}
        >
          {testimonial.description}
          <div className="testimonial-meta">
            <img src={testimonial.avatar} alt={`${testimonial.name} avatar`} />
            <p>{testimonial.name}</p>
            <p>{testimonial.title}</p>
          </div>
        </div>
      ))}
      <button onClick={goToNext}>&gt;</button>
   


      
</div> */}

{reviews.length > 0 && <>
<Swiper
      spaceBetween={50}
      slidesPerView={'auto'}

      pagination={{ clickable: true }}
      slideToClickedSlide={true}
       onSwiper={(swiper) => setSwiperRef(swiper)}
      onSlideChange={() => console.log('slide change')}
      centeredSlides={true}
      loop={true}
      className='testimonials-carousel'
    >
      <div className='fader fader-left'></div>
      <div className='fader fader-right'></div>
 
        {reviews?.map((review, index) => (
         
          <SwiperSlide key={index}>
          <div
        
            className={`testimonial-item ${index === activeIndex ? 'active' : ''}`}
          >
              <div className="desc">
                  {review.comment}
                </div>
                <div className="bottom">
                  <div className="person">
                      
                      {showCorrectAvatar(review)}
                      
                      <div className="bio">
                        <div className="name">{review.name}</div>
                        <div className="title">{review.tag}</div>
                      </div>
                  </div>
                  <div className="stars">
                    <div className='star-box'>
                      {Array.from(Array(5)).map((item, index) => (
                        
                        <div className={`star ${index + 1 <= review.stars ? 'active-star' : ''}`} key={index}><Icons.Star /></div>
                      ))}
                    </div>
                  </div>
                </div>
          </div>
          </SwiperSlide>
        ))}
         
    </Swiper>
  
  </>}

    <div className='testimonial-controls'>
      <div className='control prev' onClick={goToPrevSlide}>&lt;</div>
      <div className='control next' onClick={goToNextSlide}>&gt;</div>
    </div>


    </div>

  </div>
  )
}

export default Carousel