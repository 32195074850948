import React from 'react'
import './UnavailableScreen.scss';
function UnavailableScreen() {
  return (
    <div className='unavailable-wrap'>
        <div className='unavailable-content'>
            <h1>App currently unavailable at this location.</h1>
        </div>
    </div>
  )
}

export default UnavailableScreen