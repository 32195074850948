import React, { Fragment, useContext, useEffect } from 'react'
import './ProfileScreen.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { SignUpRegisterContext } from '../../../contexts/SignUpRegisterContext';
import { GameContext } from '../../../contexts/GameContext';
import DataAssuranceHOC from '../../hocs/DataAssuranceHOC';
import StyleHelper from '../../../helpers/StyleHelper';
import { Icons } from '../../atoms/Icons';
import DateHelper from '../../../helpers/DateHelper';
import QRCode from 'react-qr-code';
import ProfilePictureModal from '../../molecules/profile-picture-modal/ProfilePictureModal';
import ConfirmationModal from '../../molecules/confirmation-modal/ConfirmationModal';
import ProfileHeader from '../../molecules/profile/profile-header/ProfileHeader';
import { formatRGBToCSS } from '../../../contexts/PlayerContext';
import { AllRecentGame } from '../../../models/data/rewards/AllUserRewards';
import { ga } from 'react-ga';


export enum ProfileScreenTabs {
    DASHBOARD,
    REWARDS,
    RECENT_GAMES,
    TRANSACTIONS,
    VIEW_GAMES
}

function ProfileScreen() {
    const _gameContext = useContext(GameContext);
    const {business_name} = useParams();
    const _signupContext = useContext(SignUpRegisterContext);
    const navigate = useNavigate();
    const [completelyLoaded, setCompletelyLoaded] = React.useState(false);
    const [showQRModal, setShowQRModal] = React.useState(false);
    const [profileImageFile, setProfileImageFile] = React.useState<File | null>(null);
    const [currentScreenState, setCurrentScreenState] = React.useState<ProfileScreenTabs>(ProfileScreenTabs.REWARDS);
   
    const [showProfilePictureModal, setShowProfilePictureModal] = React.useState(false);

    const[AllRecentGames, setAllRecentGames] = React.useState<AllRecentGame | null>(null)
    const [loadingRecentGames, setLoadingRecentGames] = React.useState<boolean>(true);

    const getRecentGames = (userKey: string) => {
        setLoadingRecentGames((old) => true);
        let postParams = {
            "userKey": userKey,
            "limit": 10
        }
        let response = fetch(`${process.env.REACT_APP_API_URL
        }/User/GetRecentGameByUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postParams)
        }).then((response) => {
            return response.json();
        })
        .then((data: AllRecentGame) => {
            setAllRecentGames(data);
        })
        .catch((error) => {
            //console.log(error);
        }).finally(() => {
            setLoadingRecentGames(false);
        });
    }

    const handleBackButtonClick = () => {
        navigate(`/${business_name}`);
    }
    

    useEffect(() => {
       if(_signupContext.signedInUser)
       {
        console.log("signed in");
        

        if(_signupContext.loadingRewards)
        {
            _signupContext.getRewards(_signupContext.signedInUser!.user.UserKey!);
        }
       
       }
    }, []);

    useEffect(() => {
        if(_signupContext.finishedLoadingUserFromLocalStorage)
        {
      
            setCompletelyLoaded(true);
            if(loadingRecentGames == true)
        {
            console.log("yep")
            getRecentGames(_signupContext.signedInUser!.user.UserKey!);
        }
        }
    }, [_signupContext.finishedLoadingUserFromLocalStorage]);

    const handleLogoutButton = () => {
        _signupContext.logout();
        navigate(`/${business_name}`);
    }

    const handleRefreshEvent = () => {
        if(currentScreenState == ProfileScreenTabs.RECENT_GAMES)
        {
            getRecentGames(_signupContext.signedInUser!.user.UserKey!);
        }
        else if(currentScreenState == ProfileScreenTabs.REWARDS)
        {
            _signupContext.getRewards(_signupContext.signedInUser!.user.UserKey!);
        }
       
    }

    const getRewardQRValue = () => {
        let payload = {
            name: _signupContext.signedInUser!.user.Name,
            userKey: _signupContext.signedInUser!.user.UserKey,
        }
        return JSON.stringify(payload);
    }

    const handleCloseQRModal = () => {
        setShowQRModal(false);
    }
    
    

    

    const handleUploadPictureClose = () => {
        setShowProfilePictureModal(false);
        setProfileImageFile(null);
    }

    const navigateContent = (tab: ProfileScreenTabs) => {
        if(tab == currentScreenState)
        {
            return;
        }
        setCurrentScreenState(tab);
    }

    const handleNavigateToGame = (gameID: string) => {
        // open page in a new tab _blank
        window.open(`https://puttputtgo.net/${business_name}/results?gameID=${gameID}`, '_blank');
    }
    

    const [showStartGameConfirmationModal, setShowStartGameConfirmationModal] = React.useState(false);
    const showComingSoonFunctionality = () => {
        setShowStartGameConfirmationModal(true);
    }

  return (
    <DataAssuranceHOC companyParam={
        business_name !
    }>
{showProfilePictureModal && <ProfilePictureModal onClose={handleUploadPictureClose} pictureData={profileImageFile} />}


{showStartGameConfirmationModal && <ConfirmationModal 
                                            onConfirm={() => setShowStartGameConfirmationModal(false)} 
                                            confirmText="Awesome!"
                                            contentColor='#fff'
                                           >this functionality is coming very soon!
                                            </ConfirmationModal>}

        <div className='profile-screen-container'>

       
        {!completelyLoaded ? "loading" : <>
        <div className='header' style={{
           
           backgroundImage: StyleHelper.format_css_url(_gameContext.getAssetByID("profile-background"))
        }}>

       {showQRModal && <div className='profile-qr-scan-wrap'>
            <div className='profile-qr-scan-content'>
                <div className='profile-qr-scan-box'>
                <QRCode value={getRewardQRValue()} />
                </div>
                <div className='qr-scan-text'>let {_gameContext.companyData.customerName} scan your id here</div>
                <div className='qr-scan-close'>
                    <div className='close-button' onClick={handleCloseQRModal}>Close</div>
                </div>
            </div>
        </div>}

        

        <div className='_top-bar'>
            <div className='_left' onClick={handleBackButtonClick}>
                <div className='back'><Icons.Back /></div>
            </div>
            <div className='_middle'>
                <div className='logo'>
                    <Icons.PPG_Logo color='#fff' />
                </div>
            </div>
            <div className='_right'>
                <div className='logout-button' onClick={handleLogoutButton}>logout</div>
            </div>
        </div>

        <ProfileHeader currentScreenState={currentScreenState} setShowProfilePictureModal={setShowProfilePictureModal} setProfileImageFile={setProfileImageFile} />
        
        <div className="trans-bottom"></div>
        </div>
        <div className='spacer'></div>
        

        <div className='reward-id-button-wrap'>
                <div className='reward-id-button' onClick={() => setShowQRModal(true)}>reward id card</div>
            </div>

            <div className='profile-navi-wrap'>
                    <div className='profile-navi-main'>
                        <div className={
                            `profile-navi-item ${currentScreenState == ProfileScreenTabs.REWARDS ? 'active-profile-navi' : ''}`
                        } onClick={() => navigateContent(ProfileScreenTabs.REWARDS)}>Rewards</div>
                        <div className={
                            `profile-navi-item ${currentScreenState == ProfileScreenTabs.RECENT_GAMES ? 'active-profile-navi' : ''}`
                        } onClick={() => navigateContent(ProfileScreenTabs.RECENT_GAMES)}>Recent Games</div>
                    </div>
                </div>

        <div className="content-main-container">

            
            <div className='top-trans'></div>
      
            <div className='content-main'>
                <div className='empty-top-space'></div>

               

                <div className='refresh-button' onClick={handleRefreshEvent}>{_signupContext.loadingRewards ? 'loading' : 'refresh'}</div>
                {(_signupContext.allUserRewards?.companiesWithRewards && _signupContext.allUserRewards?.companiesWithRewards.length <= 0 && currentScreenState == ProfileScreenTabs.REWARDS) && <div className='no-rewards-found'>You don't have any rewards yet</div>}
                {( AllRecentGames && AllRecentGames.recentGames.length <= 0 && currentScreenState == ProfileScreenTabs.RECENT_GAMES) && <div className='no-rewards-found'>You don't have any games saved yet</div>}
               
                {currentScreenState == ProfileScreenTabs.REWARDS && <>
                <div className='rewards-container-wrap'>

                <div className='large-loader' style={{
                    display: _signupContext.loadingRewards ? 'flex' : 'none'
                }}>
                    <div className='spinner'><Icons.Gallery_Spinner /></div>
                </div>

                    {
                        _signupContext.allUserRewards?.rewardsByCompany &&
                        Object.entries(_signupContext.allUserRewards.rewardsByCompany).map(([companyKey, companyRewards], index) => {
                            return <Fragment key={index}>
                            <div className='reward-indi'>
                        <div className='reward-trans'></div>
                        <div className='reward-top' style={{
                        backgroundImage: StyleHelper.format_css_url_without_asset(`/global-assets/rewards/${companyKey}.jpg`)
                    }}>
                        <div className='darker-overlay'></div>
                            <div className='reward-customer'>
                                <div className='customer-name'>{companyKey}</div>
                                <div className='joined-on'>since {DateHelper.formatDateYearAndMonthFromString(_signupContext.signedInUser!.user.CreatedDate!.toString())}</div>
                            </div>
                            <div className='reward-points-container'>
                                <div className='reward-points'>
                                    <div className='points'>{companyRewards.availableBalance}</div>
                                    <div className='points-label'>points</div>
                                </div>
                            </div>
                        </div>
                        <div className='reward-bottom'>
                            <div className='view-reward-history' onClick={showComingSoonFunctionality}>View Transactions</div>
                            <div className='view-games' onClick={showComingSoonFunctionality}>View Games</div>
                        </div>
                    </div>
                    </Fragment>
                        })
                    }
{/* 
                    <div className='reward-indi'>
                        <div className='reward-trans'></div>
                        <div className='reward-top' style={{
                        backgroundImage: StyleHelper.format_css_url(_gameContext.getAssetByID("welcome-background"))
                    }}>
                        <div className='darker-overlay'></div>
                            <div className='reward-customer'>
                                <div className='customer-name'>castle golf</div>
                                <div className='joined-on'>since {formatDateYearAndMonth(_signupContext.signedInUser!.user.CreatedDate!.toString())}</div>
                            </div>
                            <div className='reward-points-container'>
                                <div className='reward-points'>
                                    <div className='points'>100</div>
                                    <div className='points-label'>points</div>
                                </div>
                            </div>
                        </div>
                        <div className='reward-bottom'></div>
                    </div> */}



                </div>
                </>}



                {currentScreenState == ProfileScreenTabs.RECENT_GAMES && <>
                    <div className='recent-games-container-wrap'>

                    <div className='large-loader' style={{
                    display: loadingRecentGames ? 'flex' : 'none'
                }}>
                    <div className='spinner'><Icons.Gallery_Spinner /></div>
                </div>

                            {AllRecentGames?.recentGames.map((game, index) => {
                                 let randR = Math.floor(Math.random() * 255);
                                 let randG = Math.floor(Math.random() * 255);
                                 let randB = Math.floor(Math.random() * 255);
                                 let randAvatarIndex = Math.floor(Math.random() * 15);
                                 let randomAmount = Math.floor(Math.random() * 5) + 1;
                                 let firstPlaceColor = StyleHelper.hexToRgb(game.players[0].color, "");
                            return <div className='game-indi' key={index} style={{
                                backgroundImage: StyleHelper.format_css_url(_gameContext.getAssetByID("results-player-background"))
                            }}>
                                <div className='indi-left'>
                                    <div className='left-top'>
                                        <div className='game-title'>{game.customerName}</div>
                                        <div className='game-date'>{DateHelper.formatDateYearAndMonthFromString(game.datePlayed)}</div>
                                    </div>
                                    <div className='left-bottom'>
                                        <div className='game-button' onClick={() => handleNavigateToGame(game.gameID)}>view game</div>
                                    </div>
                                </div>
                                <div className='indi-right'>
                                    <div className='avatars-wrap'>
                                        {game.players.slice(1).map((player, index) => {
                                            let randR = Math.floor(Math.random() * 255);
                                            let randG = Math.floor(Math.random() * 255);
                                            let randB = Math.floor(Math.random() * 255);
                                            let randAvatarIndex = Math.floor(Math.random() * 15);
                                            let trueColor = StyleHelper.hexToRgb(player.color, "");
                                            return <div className='single-avatar-wrap'>
                                            <div className='single-avatar' style={{
                                        backgroundImage: StyleHelper.format_css_url(_gameContext.getAssetByID('avatar-sprites')),
                                        backgroundPosition: StyleHelper.getProfilePictureAtIndex(player.avatarIndex),
                                        backgroundColor: formatRGBToCSS({ r: trueColor.r, g: trueColor.g, b: trueColor.b}, 1)
                                    }}></div></div>
                                        })}
    
                                    </div>
                                    <div className='winning-avatar-wrap'>
                                    <div className='winning-avatar' style={{
                                        backgroundImage: StyleHelper.format_css_url(_gameContext.getAssetByID('avatar-sprites')),
                                        backgroundPosition: StyleHelper.getProfilePictureAtIndex(game.players[0].avatarIndex),
                                        backgroundColor: formatRGBToCSS({ r: firstPlaceColor.r, g: firstPlaceColor.g, b: firstPlaceColor.b}, 1)
                                    }}></div>
                                        <div className='winning-frame' style={{
                                            backgroundImage: StyleHelper.format_css_url(_gameContext.getAssetByID("gameplay-player-ball-frame"))
                                        }}></div>
                                        
                                    </div>

                                </div>
                            </div>
                            })}





                    </div>
                </>}







            </div>
        </div>
        </>}
        </div>
      
    </DataAssuranceHOC>
  )
}

export default ProfileScreen