import React, { useContext, useEffect } from "react";
import "./GeoFencer.scss";
import { useLocation } from "react-router-dom";
import { Icons } from "../../atoms/Icons";
import { GameContext } from "../../../contexts/GameContext";
import CompanyGeoFenceLocation from "../../../models/data/CompanyGeoFenceLocationModel";
import CompanyHelper from "../../../helpers/CompanyHelper";
import CustomerSEOInfo from "../../../models/data/CustomerSEOInfoModel";

interface IProps {
    children?: any;
}

// const castleGolfLat = 26.523364;
// const castleGolfLng = -81.878188;
// const radiusKm = 0.3; // 500 meters, for instance

enum CurrentPermissionState {
    GRANTED,
    DENIED,
    PROMPT,
    UNKNOWN,
    NOT_SUPPORTED,
}

enum GeoFenceState {
    INSIDE,
    OUTSIDE,
    UNKNOWN,
}

function GeoFencer(props: IProps) {
    const _gameContext = useContext(GameContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const byPass = queryParams.get("bypass"); // getting the scan method

    const [permissionStatus, setPermissionStatus] = React.useState<string>("");
    const [latitude, setLatitude] = React.useState<number | null>(null);
    const [longitude, setLongitude] = React.useState<number | null>(null);

    const [geoFenceData, setGeoFenceData] = React.useState<CompanyGeoFenceLocation[] | null>(CompanyHelper.GetAllGeoFenceData("castle-golf"));
    const [seoInfo, setSeoInfo] = React.useState<CustomerSEOInfo | null>(CompanyHelper.GetCompanySEOInfo("castle-golf"));
    const [geoFenceState, setGeoFenceState] = React.useState<GeoFenceState>(
        GeoFenceState.UNKNOWN
    );
    const [currentPermission, setCurrentPermission] =
        React.useState<CurrentPermissionState>(CurrentPermissionState.UNKNOWN);

    const handleLocationPermission = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                    setPermissionStatus("Granted");

                   // //console.log("try again?");

                   for (let i = 0; i < geoFenceData!.length; i++) {
                    const withinFence = isUserWithinRadius(
                        position.coords.latitude,
                        position.coords.longitude,
                        geoFenceData![i].latLng.lat!,
                        geoFenceData![i].latLng.lng!,
                        geoFenceData![i].radius!
                    );
                    if (withinFence) {
                        setGeoFenceState(GeoFenceState.INSIDE);
                        _gameContext.updateGeoStatusEnabled(true);
                        break;
                    } 
                   }
               
                    setGeoFenceState(GeoFenceState.OUTSIDE);
                },
                (error) => {
                    setPermissionStatus("denied");
                    console.error(error);
                }
            );
        } else {
            console.error("error");
            // setButtonText('Location access denied');
            setPermissionStatus("Denied");
        }
    };

    function isUserWithinRadius(
        userLat: number,
        userLng: number,
        targetLat: number,
        targetLng: number,
        radius: number
    ) {
        const earthRadiusKm = 6371; // Radius of the earth in kilometers

        const dLat = degreesToRadians(targetLat - userLat);
        const dLng = degreesToRadians(targetLng - userLng);

        userLat = degreesToRadians(userLat);
        targetLat = degreesToRadians(targetLat);

        // Haversine formula
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLng / 2) *
                Math.sin(dLng / 2) *
                Math.cos(userLat) *
                Math.cos(targetLat);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = earthRadiusKm * c; // Distance in km

        return distance <= radius;
    }
    function degreesToRadians(degrees: number) {
        return degrees * (Math.PI / 180);
    }

    useEffect(() => {
        // const castleGolfLat = 26.523364;
        // const castleGolfLng = -81.878188;
        // const radiusKm = 0.3; // 500 meters, for instance

        // // const userCoords = [
        // //     26.523265, -81.877791
        // // ]
        // // const userLat = userCoords[0];
        // // const userLng = userCoords[1];

        // const userLat = latitude!;
        // const userLng = longitude!;

        // const withinFence = isUserWithinRadius(
        //     userLat,
        //     userLng,
        //     castleGolfLat,
        //     castleGolfLng,
        //     radiusKm
        // );

        // //console.log(
        //     withinFence ? "WELCOME TO CASTLE GOLF!" : "⚠️ YOU ARE TOO FAR AWAY"
        // ); // true if within radius, false otherwise

        let tmpCurrentPermission = checkLocationPermission();
        //  setCurrentPermission(tmpCurrentPermission);
    }, []);

    useEffect(() => {
        if (byPass) {
            if (byPass === "true") {
                _gameContext.updateGeoStatusEnabled(true);
                //setPermissionStatus("Granted");
            }
        }
    }, [byPass]);

    const checkLocationPermission = (): CurrentPermissionState => {
        // Check if the Permissions API is available
        if (navigator && navigator.permissions) {
            navigator.permissions
                .query({ name: "geolocation" as PermissionName })
                .then((result) => {
                    switch (result.state) {
                        case "granted":
                            // Permission has already been granted
                            //console.log("Location permission is granted.");
                           // TOdo

                           navigator.geolocation.getCurrentPosition(
                            (position) => {

                                for(let i = 0; i < geoFenceData!.length; i++) {
                                    const withinFence = isUserWithinRadius(
                                        position.coords.latitude,
                                        position.coords.longitude,
                                        geoFenceData![i].latLng.lat!,
                                        geoFenceData![i].latLng.lng!,
                                        geoFenceData![i].radius!
                                    );
                                    if (withinFence) {
                                        setGeoFenceState(GeoFenceState.INSIDE);
                                        _gameContext.updateGeoStatusEnabled(true);
                                        break;
                                    }
                                }
                             
        
                          
                                setGeoFenceState(GeoFenceState.OUTSIDE);
                            
                            },
                            (error) => {
                                setPermissionStatus("denied");
                                console.error(error);
                            });
                           
                           // _gameContext.updateGeoStatusEnabled(true);
                          //  //console.log(latitude, longitude)
                           


                            return CurrentPermissionState.GRANTED;


                            // You can directly call your function to get the location here
                            break;
                        case "prompt":
                            // Permission is yet to be asked
                            // //console.log(
                            //     "Location permission has not been asked yet."
                            // );
                            return CurrentPermissionState.PROMPT;
                            break;
                        case "denied":
                            // Permission has been denied
                            //console.log("Location permission is denied.");
                            return CurrentPermissionState.DENIED;
                            break;
                        default:
                            // The permission state is unknown
                            //console.log("Unknown permission state.");
                            return CurrentPermissionState.UNKNOWN;
                            break;
                    }
                })
                .catch((error: Error) => {
                    // Handle errors (if any)
                    console.error("Error checking permissions: ", error);
                    return CurrentPermissionState.UNKNOWN;
                });
        } else {
            // Permissions API is not available
            //console.log("Permissions API is not supported by this browser.");
            return CurrentPermissionState.NOT_SUPPORTED;
        }
        return CurrentPermissionState.UNKNOWN;
    };

    // if (_gameContext.geoStatusEnabled || byPass === "true") {
    //     return props.children;
    // }
    return props.children;

    return (
        <div className="geofence-container">
            <div className="geofence-wrap">
                <div className="geofence-header">
                    <div className="geofence-logo">
                        <Icons.PPG_Logo color="#fff" />
                    </div>
                </div>
                <div className="geofence-body">
                    <div className="geofence-body-inner">
                        <div className="geo-spacer"></div>

                        {geoFenceState === GeoFenceState.OUTSIDE && <>
                            <h1>You are too far away from {seoInfo?.name}</h1>
                        </>}
                        {geoFenceState === GeoFenceState.UNKNOWN && <>

                        <p>
                            To gain access to our digital scorecard, we need to
                            verify your presence at our course. Please enable
                            location services on your device to continue.
                        </p>

                        <p>
                            Your privacy is important to us. Location data is
                            used solely for verifying your position and
                            providing the digital scorecard service. Thank you
                            for helping us create the best experience for you!
                        </p>
                        </>}

                        {/* 
<p><strong>How to enable:</strong></p>
<div className="geofence-steps">
    <div className="geofence-step">
        <div className="geofence-step-number"><span>1</span></div>
        <div className="geofence-step-text">Click the 'Enable Location' button below.</div>
    </div>
    <div className="geofence-step">
        <div className="geofence-step-number"><span>1</span></div>
        <div className="geofence-step-text">When prompted, choose 'Allow' to share your location with us.</div>
    </div>
    <div className="geofence-step">
        <div className="geofence-step-number"><span>1</span></div>
        <div className="geofence-step-text">If you accidentally denied permission, please check our <a href="#">help page</a> for guidance on how to grant it.</div>
    </div>
</div> */}
                    </div>
                </div>

                <div className="geofence-footer">
                    <button
                        className="enable-location-button"
                        onClick={handleLocationPermission}
                    >
                        {geoFenceState === GeoFenceState.OUTSIDE ? "Try Again" : "Enable Location And Enter"}
                    </button>
                </div>
            </div>
           
        </div>
    );
}

export default GeoFencer;
