import React, {
  Fragment,
  createRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "./HomeScreen.scss";
import {
  browserName,
  browserVersion,
  deviceType,
  osName,
} from "react-device-detect";
import { useLocation } from "react-router-dom";
import { Icons } from "../../../atoms/Icons";
import LineComponent from "../components/line/LineComponent";
import Carousel from "../components/carousel/Carousel";

const benefitsContent = [
  {
    title: "Enhanced Customer Engagement",
    description: (
      <Fragment>
        <p>
        PuttPuttGO's app features, like leaderboards and achievement tracking, foster a competitive and engaging environment. This enhanced engagement keeps players returning, increasing customer loyalty and repeat business.
        </p>
      </Fragment>
    ),
    icon: `${process.env.PUBLIC_URL}/website/enhanced-engagement.jpg`,
  },
  {
    title: "Data-Driven Insights",
    description: (
      <Fragment>
        <p>
        Gain valuable insights into customer behavior and preferences through data collected via the app. This information can be used to tailor marketing strategies, improve customer experiences, and drive business growth.
        </p>
      </Fragment>
    ),
    icon: `${process.env.PUBLIC_URL}/website/data-driven.jpg`,
  },
  {
    title: "Revolutionary Rewards System",
    description: (
      <Fragment>
        <p>
        PuttPuttGO revolutionizes the mini-golf experience with an innovative reward system, designed to enhance player engagement and loyalty. Our system allows users to accumulate reward points through various enjoyable activities, making every visit and action more rewarding.
        </p>
      </Fragment>
    ),
    icon: `${process.env.PUBLIC_URL}/website/rewards.jpg`,
  },
];

interface ContactFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  message: string;
}

function HomeScreen() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const scanMethod = queryParams.get("method"); // getting the scan method
  const [hasSendScan, setHasSendScan] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [contactForm, setContactForm] = useState<ContactFormValues>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    message: "",
  });

  const updateContactForm = (key: string, value: string) => {
    setContactForm({
      ...contactForm,
      [key]: value,
    });
  }

  const getYear = () => {
    return new Date().getFullYear();
  };

  const validateForm = () => {
    let isValid = true;
  
    // Use keyof to iterate over known keys of ContactFormValues
    Object.keys(contactForm).forEach(key => {
      // Ensure that key is actually a key of ContactFormValues
      if(isValid == false)
      {
        return;
      }
      if (key as keyof ContactFormValues) {
        // Now TypeScript knows that contactForm[key] is safe
        if (!contactForm[key as keyof ContactFormValues]) {
          isValid = false;
          
          alert(`${key} cannot be empty`);
          return;
        }
      }
    });
  
    return isValid;
  };

  const submitForm = () => {
    let isValid = validateForm();
    if(isValid)
    {
      let payload = {
        firstName: contactForm.firstName,
        lastName: contactForm.lastName,
        email: contactForm.email,
        phone: contactForm.phoneNumber,
        company: contactForm.companyName,
        comments: contactForm.message,
      }

      setShowLoading(true);

      fetch(`${process.env.REACT_APP_API_URL}/Game/SaveContactForm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }).then((res) => {
        setShowLoading(false);
        if(res.status == 200)
        {
          alert("Your request has been sent successfully!");
          setContactForm({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            companyName: "",
            message: "",
          });
        }
        else
        {
          alert("Something went wrong. Please try again later.");
        }
      }).catch((err) => {
        setShowLoading(false);
        alert("Something went wrong. Please try again later.");
      });


    }
  }

  useEffect(() => {
    if (!hasSendScan) {
      if (scanMethod) {
        let payload = {
          customerKey: "PPG-Website",
          gameID: "",
          browser: browserName,
          browserVersion: browserVersion,
          device: deviceType,
          OS: osName,
          method: scanMethod,
        };
        ////console.log("PAYLOAD", payload)
        fetch(`${process.env.REACT_APP_API_URL}/Game/SaveQRScan`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }).catch((err) => {
          //console.log(err);
        });
        setHasSendScan(true);
      }
    }
  }, []);


  return (
    <div id="home-screen">
      <div className="header">
        <div className="left">
          {/* <div className="navi-item">Overview</div> */}
        </div>
        <div className="center">
          <div className="logo">
            <Icons.PPG_Logo
              color="#fff"
              filterColor="0,0,0"
              filterOpacity={0.3}
            />
          </div>
        </div>
        <div className="right">
          
          {/* <div className="navi-item">Contact Us</div> */}
        </div>
      </div>

      <div
        className="hero-wrap"
        style={{
          // backgroundImage: `url(${process.env.PUBLIC_URL}/website/background.jpg)`,
        }}
      >
        <div className="hero-video">
          <video autoPlay muted playsInline src="/website/new_video.mp4"></video>
        </div>
        <div className="hero-phone">
          <img
            src={`${process.env.PUBLIC_URL}/website/phone.png`}
            alt="phone"
          />
        </div>
        <div className="hero-gradient" style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/website/hero-gradient.png)`,
        }}>
        </div>
      </div>

      <div className="main-content">
        <div
          className="content-block embrace-revolution"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/website/bottom-cap.jpg)`,
          }}
        >
          <h3>EMBRACE THE</h3>
          <h1>DIGITAL REVOLUTION</h1>
          <div className="desc">
            <p>
            In the heart of the mini-golf world, PuttPuttGO stands at the forefront of a digital revolution, transforming traditional play into an immersive, tech-driven experience. By embracing this digital shift, we're not just enhancing the game – we're redefining it. Our innovative platform integrates digital scorecards, a dynamic reward system, and social media interaction, all seamlessly interwoven to elevate both the player's journey and the course operator's potential.</p>
            
          
          </div>
        </div>

        <div className="content-block benefits-wrap">
          {benefitsContent.map((item, index) => {
            return (
              <Fragment key={index}>
                <div
                  className={`benefit ${
                    index % 2 === 0 ? "leftSide" : "rightSide"
                  }`}
                >
                  <div className="desc">
                    <h2>{item.title}</h2>
                    {item.description}
                    <div className={`anchor-point-${index % 2 == 1 ? 'top' : 'bottom'}`}></div>
                  </div>
                  <div className="icon">
                    <div className="icon-item" style={{
                      backgroundImage: `url(${item.icon})`,
                    }}></div>
                  </div>
                </div>
              </Fragment>
            );
          })}
        </div>

       
          <Carousel />



          <div className="content-block contact-wrap">

            {showLoading &&
            <div className="loading-wrap">
              <div className="loading-text">Sending Request...</div>
            </div>}

              <div className="left-block" style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/website/contact.jpg)`,
              }}>
                


          

              </div>
              <div className="right-block">
                  <h1>Want to try it?</h1>
                  <h2>SIGN UP BELOW TO ACCESS OUR DEMO</h2>
                
                  <div className="form-wrap">

                      <div className="row">
                        <div className="item">
                          <b>Firstname</b>
                          <input type="text" value={contactForm.firstName} onChange={(e) => updateContactForm("firstName", e.target.value)} />
                        </div>
                        <div className="item">
                          <b>Lastname</b>
                          <input type="text" value={contactForm.lastName} onChange={(e) => updateContactForm("lastName", e.target.value)} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="item">
                          <b>Email Address</b>
                          <input type="email" value={contactForm.email} onChange={(e) => updateContactForm("email", e.target.value)} />
                        </div>
                        <div className="item">
                          <b>Phone Number</b>
                          <input type="tel" value={contactForm.phoneNumber} onChange={(e) => updateContactForm("phoneNumber", e.target.value)} />
                        </div>
                      </div>

                      <div className="row">
                      <div className="item">
                          <b>Company Name</b>
                          <input type="text" value={contactForm.companyName} onChange={(e) => updateContactForm("companyName", e.target.value)} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="item">
                          <b>Anything you'd like to let us know about you?</b>
                          <textarea value={contactForm.message} onChange={(e) => updateContactForm("message", e.target.value)}></textarea>
                        </div>
                      </div>

                      <div className="row">
                        <div className="item">
                          <button className="submit-button" onClick={submitForm}>Submit</button>
                        </div>
                      </div>

                  </div>  
              </div>
          </div>

          <div className="footer-wrap" style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/website/top-cap.jpg)`,
          }}>

            <div className="footer">
              <div className="small-logo"><Icons.PPG_Logo color="#fff" filterColor="0,0,0" filterOpacity={0.3} /></div>
              <div className="social-icons"></div>
              <div className="contact-info">
                <div className="item">Phone: 239.210.8816</div>
                <div className="item">Email: <a href="mailto:jon@puttputtgo.net">jon@puttputtgo.net</a></div>
              </div>
              <div className="copyright">
                <p>Copyright © {getYear()} PuttPuttGO LLC</p>
                <p>
                All Rights Reserved</p>
               

              </div>
            </div>

          </div>


      </div>
      {/* end main content */}
    </div>
  );
}

export default HomeScreen;
