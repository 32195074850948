import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import CompanyHelper from '../../helpers/CompanyHelper';

interface ICustomerPageSEOProps {
    customerID: string;
}
function CustomerPageSEO(props: ICustomerPageSEOProps) {
    const findCustomer = CompanyHelper.GetCompanySEOInfo(props.customerID);
    useEffect(() => {

    }, []);

    if(findCustomer) {
  return (
    <Helmet>
        <title>{findCustomer?.metaTitle}</title>
        <meta property="fb:app_id" content="823028086097162" />
        <meta property="og:title" content={findCustomer?.metaTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.puttputtgo.net/${customer.id}" />
        <meta property="og:image" content={findCustomer?.metaImage} />
        <meta property="og:description" content={findCustomer?.metaDescription} />
        <meta property="og:image:width" content="700" />
        <meta property="og:image:height" content="375" />
        <meta property="og:site_name" content="PuttPuttGO" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@PuttPuttGO" />
        <meta name="twitter:title" content={findCustomer?.metaTitle} />
        <meta name="twitter:description" content={findCustomer?.metaDescription} />
        <meta name="twitter:image" content={findCustomer?.metaImage} />
        <meta name="twitter:image:alt" content={findCustomer?.metaTitle} />
        <meta name="twitter:creator" content="@PuttPuttGO" />
        <meta name="robots" content="index, follow" />
        <link rel='shortcut icon' type='image/x-icon' href='../customers/castle-golf/images/favicon.ico' />
        
        <meta name="description" content={findCustomer?.metaDescription} />
    </Helmet>
  )
    }
    return null;
}

export default CustomerPageSEO