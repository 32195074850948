import React, { useContext } from "react";
import "./ResultsSignUp.scss";
import { Icons } from "../../../atoms/Icons";
import StyleHelper from "../../../../helpers/StyleHelper";
import { GameContext } from "../../../../contexts/GameContext";
import { useNavigate } from "react-router-dom";
import {
    SignInRegisterScreenPages,
    SignUpRegisterContext,
} from "../../../../contexts/SignUpRegisterContext";
import {
    PlayerContext,
    formatRGBToCSS,
} from "../../../../contexts/PlayerContext";

enum ResultsSignUpState {
    Start,
    PlayerChooser,
}

function ResultsSignUp() {
    const _gameContext = useContext(GameContext);
    const _playerContext = useContext(PlayerContext);
    const _signupContext = useContext(SignUpRegisterContext);
    const navigate = useNavigate();
    const [signUpState, setSignUpState] = React.useState<ResultsSignUpState>(
        ResultsSignUpState.Start
    );
    const [chosenPlayerID, setChosenPlayerID] = React.useState<number>(_playerContext.getAllPlayers()[0].id);

    const handleRegisterClick = () => {
        _signupContext.updateCurrentScreen(SignInRegisterScreenPages.Register);
        _signupContext.updateRegisteredWithGame(_gameContext.gameID, _playerContext.getPlayerById(chosenPlayerID)?.id!,  _playerContext.getPlayerById(chosenPlayerID)?.name!, _gameContext.companyData.customerID);
        navigate(`/${_gameContext.companyData.customerID}/signin`);
    };

    const handlePlayerClick = (playerIndex: number) => {
        setChosenPlayerID(playerIndex);
    }
    const handleGoToPlayerChooserClick = () => {
        setSignUpState(ResultsSignUpState.PlayerChooser);
    }
    return (
        <div
            className="signup-reminder-wrap"
            style={{
                backgroundImage: StyleHelper.format_css_url(
                    _gameContext.getAssetByID("signin-background")
                ),
            }}
        >
            <div className="signup-fade"></div>
            <div className="signup-dark"></div>
            <div className="signup-content">
                {signUpState == ResultsSignUpState.Start && (
                    <>
                        <div className="signup-title">
                            Keep track of
                            <br />
                            your games!
                        </div>
                        <div className="signup-text">
                            Sign up to save your scores and track your progress.
                        </div>
                        <div
                            className="signup-button"
                            onClick={handleGoToPlayerChooserClick}
                        >
                            choose player
                        </div>
                        <div className="signup-ppg-logo">
                            <Icons.PPG_Logo color="#fff" />
                        </div>
                    </>
                )}
                {signUpState == ResultsSignUpState.PlayerChooser && (
                    <>
                        <div className="signup-title">
                            Which player
                            <br />
                            were you?
                        </div>

                        <div className="player-chooser-wrap">
                            {_playerContext
                                .getAllPlayers()
                                .map((player, index) => {
                                    return (
                                        <div
                                        key={index}
                                            onClick={() => handlePlayerClick(player.id)}
                                            className={`player-choose-indi ${
                                                chosenPlayerID ==
                                                player.id
                                                    ? "active-chosen"
                                                    : ""
                                            }`}
                                        >
                                            <div
                                                className="choose-avatar"
                                                style={{
                                                    backgroundImage:
                                                        StyleHelper.format_css_url(
                                                            _gameContext.getAssetByID(
                                                                "avatar-sprites"
                                                            )
                                                        ),
                                                    backgroundPosition:
                                                        StyleHelper.getProfilePictureAtIndex(
                                                            player.avatarIndex!
                                                        ),
                                                    backgroundColor:
                                                        formatRGBToCSS(
                                                            player.color!,
                                                            1
                                                        ),
                                                }}
                                            ></div>
                                        </div>
                                    );
                                })}
                        </div>
                        <div className="chosen-player-name">
                            <div className="label">Username</div>
                            <div className="username">{_playerContext.getPlayerById(chosenPlayerID)?.name}</div>
                            <div className="small">you can change this in the account creation screen.</div>
                        </div>
                        <div
                            className="signup-button"
                            onClick={handleRegisterClick}
                        >
                            create account
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default ResultsSignUp;
