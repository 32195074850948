import React, { useContext, useEffect, useRef, useState } from "react";
import "./CameraFeedContainer.scss";
import { CameraContext } from "../../../../contexts/CameraContext";
import { GameContext } from "../../../../contexts/GameContext";
import Webcam from "react-webcam";

interface IProps {
  onClose: () => void;
  videoRef: any;
  facingMode: "user" | "environment";
  updateFacingMode: (facingMode: "user" | "environment") => void;
}
interface ICameraDebugInfo {
    key: string;
    value: string;
}
function CameraFeedContainer(props: IProps) {
  const _cameraContext = useContext(CameraContext);
  const _gameContext = useContext(GameContext);
  const [videoStarted, setVideoStarted] = useState<boolean>(false);
  
  const [cameraDebugInfo, setCameraDebugInfo] = useState<ICameraDebugInfo[]>([]);

  const log = (key: string, value: string) => {
    setCameraDebugInfo((prevState) => {
      return [...prevState, {key, value}];
    });
  }

  const [lastTap, setLastTap] = React.useState<number>(0);
  const cameraOverlayRef = useRef<HTMLDivElement>(null);
  const delay = 300;

  const handleDoubleTapEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const now = Date.now();
    if (now - lastTap < delay!) { // the "!" is used to assert that delay is not undefined, which we know because of the default value
      //  switchCamera();
        props.updateFacingMode(props.facingMode == "user" ? "environment" : "user");
       // //console.log("DOUBLE TAP");
        setLastTap(0); // Reset it so it doesn't interfere with further double taps
    } else {
    //  //console.log("no double tap")
        setLastTap(now);
    }
  }




  

  return (
    <div className="camera-feed-container" ref={cameraOverlayRef} onClick={handleDoubleTapEvent}>
      {/* <div className="back" onClick={props.onClose}>
        Back
      </div> */}
     
      <div className="cameraWrap" style={{
        
        transform: `scaleX(${props.facingMode == "environment" ? "-1" : "1"})`,
      }}>
        <Webcam ref={props.videoRef}
        screenshotQuality={1}
        audio={false}
        screenshotFormat="image/jpeg"
        videoConstraints={{
          facingMode: props.facingMode,
          width: 1920,
          height: 1080,
        }}
        mirrored={props.facingMode == "user" ? true : false}
        />
        

        </div>
    </div>
  );
}

export default CameraFeedContainer;
