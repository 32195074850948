import RGBModel from "../models/color/RGBModel";

export enum Gender {
    MALE = 0,
    FEMALE = 1
  }
  
export const avatarGenders = [
    {id: 0, gender: Gender.MALE},
    {id: 1, gender: Gender.MALE},
    {id: 2, gender: Gender.MALE},
    {id: 3, gender: Gender.MALE},
    {id: 4, gender: Gender.MALE},
    {id: 5, gender: Gender.MALE},
    {id: 6, gender: Gender.MALE},
    {id: 7, gender: Gender.MALE},
    {id: 8, gender: Gender.FEMALE},
    {id: 9, gender: Gender.FEMALE},
    {id: 10, gender: Gender.FEMALE},
    {id: 11, gender: Gender.FEMALE},
    {id: 12, gender: Gender.FEMALE},
    {id: 13, gender: Gender.FEMALE},
    {id: 14, gender: Gender.FEMALE},
    {id: 15, gender: Gender.FEMALE}
  ]

export default class StyleHelper {
    static format_css_url(asset : any) {
        if (asset == null || asset == undefined) 
            return '';
        

        return `url('${
            asset.assetLocation
        }')`;
    }
    static format_css_url_without_asset(url : any) {
        if (url == null || url == undefined) 
            return '';
        

        return `url('${
            url
        }')`;
    }
    static RGBToHSL = (r : number, g : number, b : number) => {
        r /= 255;
        g /= 255;
        b /= 255;
        const l = Math.max(r, g, b);
        const s = l - Math.min(r, g, b);
        const h = s ? l === r ? (g - b) / s : l === g ? 2 + (b - r) / s : 4 + (r - g) / s : 0;
        return [
            60 * h < 0 ? 60 * h + 360 : 60 * h,
            100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
            (100 * (2 * l - s)) / 2,
        ];
    };

    static rgbToHex(r: number, g: number, b: number): string {
        // Ensure values are within the valid range
        r = Math.max(0, Math.min(255, r));
        g = Math.max(0, Math.min(255, g));
        b = Math.max(0, Math.min(255, b));
      
        // Convert the numbers to a hexadecimal string
        return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
    }
    static hexToRgb(hex: string, name?: string): RGBModel {
        // Remove any leading '#' if present
        hex = hex.replace(/^#/, '');
      
        // Parse the hex string into RGB components
        let r = 0, g = 0, b = 0;
        if (hex.length === 3) { // Handle shorthand hex color
          r = parseInt(hex[0] + hex[0], 16);
          g = parseInt(hex[1] + hex[1], 16);
          b = parseInt(hex[2] + hex[2], 16);
        } else if (hex.length === 6) { // Handle full hex color
          r = parseInt(hex.substring(0, 2), 16);
          g = parseInt(hex.substring(2, 4), 16);
          b = parseInt(hex.substring(4, 6), 16);
        }
      
        // Return the RGBModel object
        return {r, g, b, name: name};
      }

    static getProfilePictureAtIndex(index: number): string {
        let gender = StyleHelper.getGenderFromIndex(index);
    
        // Adjust index if the gender is FEMALE to start from the beginning of the female avatars in the sprite sheet
        const adjustedIndex = (gender === Gender.FEMALE) ? index - 8 : index;
    
        // Calculate the row and column based on the adjusted index
        const row = Math.floor(adjustedIndex / 4); // This will be 0 or 1 for both genders
        const col = adjustedIndex % 4; // This will be 0, 1, 2, or 3 for both genders
    
        // Calculate the negative percentage for the background-position for a stretched sprite sheet.
        // Since each sprite's size is 25% of the sprite sheet, we use -100% to shift one full sprite's width/height.
        const xPosPercentage = -(col * 100); // -0%, -100%, -200%, or -300% for 4 images per row
        const yPosPercentage = -(row * 100); // -0% or -100% for males, -200% or -300% for females.
    
        // Adjust yPosPercentage based on gender.
        // Males are on rows 0 and 1, females on rows 2 and 3.
        const adjustedYPosPercentage = (gender === Gender.FEMALE) ? yPosPercentage - 200 : yPosPercentage;
    
        //console.log(`Avatar Index: ${index} xPos: ${xPosPercentage} yPos: ${adjustedYPosPercentage}`);
        return `${xPosPercentage}% ${adjustedYPosPercentage}%`;
    }
      
      
      static getGenderFromIndex(index: number)
      {
        return avatarGenders.find((avatar) => avatar.id === index)?.gender;
      }
      
  
  
  
}
