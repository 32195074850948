import React from 'react'
import './CameraFilterViewer.scss';
import StyleHelper from '../../../../helpers/StyleHelper';
import { BlendMode } from '../../../../helpers/SnapHelper';

interface IProps {
    selectedFilter: string | null;
    blendMode: BlendMode;
}
function CameraFilterViewer(props: IProps) {
  return <div
  className='camera-filter-viewer'
  style={{
    backgroundImage: props.selectedFilter ? StyleHelper.format_css_url_without_asset(props.selectedFilter) : undefined,
    mixBlendMode: props.blendMode
  }}
></div>
}

export default CameraFilterViewer