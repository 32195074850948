import React, { useState, createContext, useRef } from "react";

interface CameraContextProps {
    cameraEnabled: boolean;
    updateCameraEnabled: (cameraEnabled: boolean) => void;
    saveToCardEnabled: boolean;
    updateSaveToCardEnabled: (saveToCardEnabled: boolean) => void;
}
interface ProviderProps {
    children: any;
}


const CameraContext = createContext<CameraContextProps>(null as any);

const CameraContextProvider = (props: ProviderProps) => {
    
    const [cameraEnabled, setCameraEnabled] = useState<boolean>(false);
    const [saveToCardEnabled, setSaveToCardEnabled] = useState<boolean>(true);

    const updateCameraEnabled = (cameraEnabled: boolean) => {
        setCameraEnabled(cameraEnabled);
    }

    const updateSaveToCardEnabled = (saveToCardEnabled: boolean) => {
        setSaveToCardEnabled(saveToCardEnabled);
    }

    const returnVal : CameraContextProps = {
        cameraEnabled,
        updateCameraEnabled,
        saveToCardEnabled,
        updateSaveToCardEnabled
    }

    return <CameraContext.Provider value={returnVal}>{props.children}</CameraContext.Provider>;
};

export { CameraContext, CameraContextProvider}