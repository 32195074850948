import React, {useContext, useEffect, useState} from 'react'
import './SettingsPage.scss';
import {useNavigate, useParams} from 'react-router-dom';
import {GameContext, GameStatus} from '../../../contexts/GameContext';
import CompanyHelper from '../../../helpers/CompanyHelper';
import WelcomeTemplate from '../../templates/welcome-screen/WelcomeTemplate';
import DataAssuranceHOC from '../../hocs/DataAssuranceHOC';
import { PlayerContext } from '../../../contexts/PlayerContext';
import RGBModel from '../../../models/color/RGBModel';
import { ScoreContext } from '../../../contexts/ScoreContext';
import { CourseContext } from '../../../contexts/CourseContext';

function SettingsPage() {
    const _gameContext = useContext(GameContext);
    const _playerContext = useContext(PlayerContext);
    const _scoreContext = useContext(ScoreContext);
    const _courseContext = useContext(CourseContext);
    const { business_name } = useParams();
    const [isReady, setIsReady] = useState(false);
    const navigate = useNavigate();
    const [adminMode, setAdminMode] = useState(false);

    useEffect(() =>{
        _gameContext.updateactivePage("settings");
        //console.log(_gameContext.gameStatus);
    }, []);

    const setGameStatus = (status: number) => {
        _gameContext.updateGameStatus(status);
    }

    const goBackButton = () => {
        _gameContext.updateactivePage("donotshow_existing_game");
        navigate(`/${business_name}`);
    };

    const handleOtherThemeClick = (place: string) => {
        _gameContext.updateCompanyParam('');
        window.location.href = place;
    };


    const handleTempAddPlayersClick = () => {

        _scoreContext.resetScores();
        _playerContext.resetPlayers();
    
        let names : any[string] = ["Jonathan", "Melanie", "Jessica", "Hunter", "Katie"];
        names = names.sort(() => Math.random() - 0.5);
        let colors : RGBModel[] = [
         { name: "red", r: 255, g: 0, b: 0 },
            { name: "yellow", r: 255, g: 255, b: 0 },
            { name: "green", r: 0, g: 255, b: 0 },
            { name: "blue", r: 0, g: 0, b: 255 },
            { name: "white", r: 255, g: 255, b: 255 },
            { name: "purple", r: 128, g: 0, b: 128 },
            { name: "black", r: 0, g: 0, b: 0 },
            { name: "teal", r: 0, g: 128, b: 128 },
            { name: "orange", r: 255, g: 165, b: 0 },
            { name: "dark_blue", r: 0, g: 0, b: 139 },
            { name: "pink", r: 255, g: 192, b: 203 },
        ]
    
        let colors_random = colors.sort(() => Math.random() - 0.5);

        let randomAvatarArr = [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15
        ].sort(() => Math.random() - 0.5);
        
    
        for (let i = 0; i < names.length; i++) {
          let randomAvatarIndex = randomAvatarArr[i];
          let rgb = colors_random[i];
          let myName = names[i];
          if(i == 0) {
            _playerContext.addPlayer({
                id: i,
                name: myName,
                color: rgb,
                avatarIndex: randomAvatarIndex,
                userKey: "687ffe2eca"
              });
          }
          else
          {
            _playerContext.addPlayer({
                id: i,
                name: myName,
                color: rgb,
                avatarIndex: randomAvatarIndex
              });
          }
          let percentChanceForHoleInOne = 0.05;
          for (let x = 0; x < _courseContext.getCurrentCourse().holes.length - 1; x++) {
            let score = 0;
            let random = Math.random();
            if (random < percentChanceForHoleInOne) {
                score = 1;
            } else if (random < 0.2) {
                score = 2;
            } else if (random < 0.4) {
                score = 3;
            } else if (random < 0.6) {
                score = 4;
            } else if (random < 0.8) {
                score = 5;
            } else {
                score = 6;
            }
            _scoreContext.addScore({
              courseID: _courseContext.getCurrentCourse().ID,
              holeID: _courseContext.getCurrentCourse().holes[x].number,
              playerID: i,
              score: score,
            });
          }
        }
        _gameContext.updateGameStatus(GameStatus.Active);
        _courseContext.updateCurrentHole(17);
        
      };

      const handleClickAdminMode = () => {
           
            if(adminMode == true) {
                setAdminMode(false);
                return;
            }
            let prompt = window.prompt("Enter Admin Password");
            if (prompt === "ppg") {
                setAdminMode(true);
            }
            //setAdminMode(!adminMode);
        }

        const handleGiveCurrentPlayersScores = () => {
            let percentChanceForHoleInOne = 0.05;
            for (let i = 0; i < _playerContext.getAllPlayers().length; i++) {
                console.log("PLAYER", _playerContext.getAllPlayers()[i]);
                for (let x = 0; x < _courseContext.getCurrentCourse().holes.length - 1; x++) {
                    let score = 0;
                    let random = Math.random();
                    if (random < percentChanceForHoleInOne) {
                        score = 1;
                    } else if (random < 0.2) {
                        score = 2;
                    } else if (random < 0.4) {
                        score = 3;
                    } else if (random < 0.6) {
                        score = 4;
                    } else if (random < 0.8) {
                        score = 5;
                    } else {
                        score = 6;
                    }
                    _scoreContext.addScore({
                        courseID: _courseContext.getCurrentCourse().ID,
                        holeID: _courseContext.getCurrentCourse().holes[x].number,
                        playerID: _playerContext.getAllPlayers()[i].id,
                        score: score,
                    });
                    console.log("SCORE", score);
                }
            }
            _gameContext.updateGameStatus(GameStatus.Active);
        _courseContext.updateCurrentHole(17);
        }

     
    return (
      <DataAssuranceHOC companyParam={business_name!}>
        <WelcomeTemplate specifiedClass='settings-override'>
            <div className='settings-page'>
               <div className='settings-body'>

                <div className='admin-mode-wrap'>
                    <div className='title' onClick={handleClickAdminMode}>{!adminMode ? 'Enter' : 'Exit'} Admin Mode</div>
                    <div className='admin-mode-hidden' style={{
                        display: adminMode ? 'block' : 'none'
                    }}>

                    <div onClick={handleTempAddPlayersClick}> -- Add Temp Players</div><br />
                    <div onClick={() => _gameContext.resetGame()}> -- Reset Game</div><br />

                    {_playerContext.getAllPlayers().length > 0 && <>
                        <div onClick={handleGiveCurrentPlayersScores}>Give Current Players Scores</div>
                    </>}

                    Game State: <br />
                    <button style={{
                        backgroundColor: _gameContext.gameStatus === 0 ? 'green' : '#fff'
                    }}
                    onClick={() => setGameStatus(0)}>Not Started</button>
                    <button style={{
                        backgroundColor: _gameContext.gameStatus === 1 ? 'green' : '#fff'
                    }}
                    onClick={() => setGameStatus(1)}>Active</button>
                    <button  style={{
                        backgroundColor: _gameContext.gameStatus === 2 ? 'green' : '#fff'
                    }}
                    onClick={() => setGameStatus(2)}>Finished</button>




<div className='button rules-button' style={{
    marginTop: '20px'
}}>
                        <div onClick={() => handleOtherThemeClick('/jungle-golf/')}>Jungle Golf</div>
                    </div><br />
                     <div className='button rules-button'>
                        <div onClick={() => handleOtherThemeClick('/castle-golf/')}>Castle Golf</div>
                    </div><br />
                    <div className='button rules-button'>
                        <div onClick={() => handleOtherThemeClick('/smugglers-cove/')}>Smugglers Cove</div>
                    </div>

                    </div>
                </div>

                <div className='button rules-button' onClick={() => navigate(`/${business_name}/signin`)}>Sign In</div>
                    
                </div>
               <div className='settings-footer'>
                    <div className='back-button'>
                        <span>Back</span>
                    <img src={_gameContext.getAssetByID("settings-button")?.assetLocation} onClick={goBackButton} />
                    </div>
                    
                </div>
            </div>
        </WelcomeTemplate>
      </DataAssuranceHOC>
    );
  }

export default SettingsPage
