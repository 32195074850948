import React, { useContext, useState } from "react";
import "./CameraSaveSnapContainer.scss";
import { CameraContext } from "../../../../contexts/CameraContext";

function CameraSaveSnapContainer() {
    
    const _cameraContext = useContext(CameraContext);

  

  return (
    <div className="camera-save-snap-container">
        <div className="inside-container">
            <div className="save-to-card-button"><label><input type="checkbox" checked={_cameraContext.saveToCardEnabled} onChange={() => _cameraContext.updateSaveToCardEnabled(!_cameraContext.saveToCardEnabled)} /><span>save to scorecard</span></label></div>
      <div className="snapped-save-text" >hold to save</div>
      </div>
    </div>
  );
}

export default CameraSaveSnapContainer;
