import React, {useContext, useEffect, useState} from 'react'
import './CameraFilterChooser.scss'
import StyleHelper from '../../../../helpers/StyleHelper';
import {CompanyDataAssetAttributesModel} from '../../../../models/data/CompanyDataModel';
import {GameContext} from '../../../../contexts/GameContext';
import SnapHelper, {BlendMode} from '../../../../helpers/SnapHelper';
import {CameraContext} from '../../../../contexts/CameraContext';

interface IProps {
    filterContainerRef: any;
    updateSelectedFilter: (filter: string | null) => void;
    updateBlendMode: (blendMode: BlendMode) => void;
    snappedPicture: () => void;
}
function CameraFilterChooser(props : IProps) {
    const _gameContext = useContext(GameContext);
    const _cameraContext = useContext(CameraContext);

    const [filterCount, setFilterCount] = useState < number > (0);

    const [alreadyCenteredFirstFilter, setAlreadyCenteredFirstFilter] = useState(false);
    const [alreadyLoadedHandleScroll, setAlreadyLoadedHandleScroll] = useState(false);

    const specificFilterTapped = (index : number) => {
        centerOnElement(props.filterContainerRef.current ?. children[index] as HTMLDivElement);
    }

    const centerOnElement = (el : HTMLDivElement) => {
        const parent = el.parentElement;
        if (parent ?. classList.contains('empty-icon')) {
            return;
        }
        const parentCenter = parent !.clientWidth / 2;
        const elCenter = el.clientWidth / 2;
        parent !.scrollLeft = el.offsetLeft - parentCenter + elCenter;
        
    };

    useEffect(() => {

        const filterContainer = props.filterContainerRef.current;
        if (! filterContainer) 
            return;
        

        if (alreadyLoadedHandleScroll) 
            return;
        

        if (! _cameraContext.cameraEnabled) 
            return;
        

        if (!alreadyCenteredFirstFilter) {
            if (filterContainer && filterContainer.children.length > 1 && !alreadyCenteredFirstFilter) { // centerOnElement(filterContainer.children[1] as HTMLDivElement);
                specificFilterTapped(1);
                setAlreadyCenteredFirstFilter(true);
            }
        }

        const handleScroll = () => {
            const children = Array.from(filterContainer.children)as HTMLDivElement[];
            let closest: HTMLDivElement | null = null;
            let closestDistance = Infinity;
            const maxScroll = filterContainer.scrollWidth - filterContainer.clientWidth;
            const currentScroll = filterContainer.scrollLeft;
            const nearEnd = maxScroll - currentScroll < 200;

            children.forEach((child) => {
                const childCenter = child.offsetLeft + (child.offsetWidth / 2);
                const containerCenter = filterContainer.scrollLeft + (filterContainer.offsetWidth / 2);
                const distance = Math.abs(childCenter - containerCenter);

                if (distance < closestDistance) {
                    closestDistance = distance;
                    closest = child;
                }
            });

            if (closest) {
                const closestElement = closest as HTMLDivElement;
                const closestImg = closestElement.querySelector('.filter-image')as HTMLDivElement;
                if (closestImg.dataset.id == "") {
                    props.updateSelectedFilter(null);
                
                    closestImg.style.width = "0%";
                    closestImg.style.height = "0%";
                } else {
                    props.updateSelectedFilter(closestImg.dataset.id as string);
           
                    closestImg.style.width = "100%";
                    closestImg.style.height = "100%";
                }
              


                if (closestImg.dataset.blend) {
                   props.updateBlendMode(closestImg.dataset.blend as BlendMode);

                } else {
                    props.updateBlendMode('normal');
                } children.forEach(child => {
                    if (child !== closest) {
                        const img = child.querySelector('.filter-image')as HTMLDivElement;
                        img.style.width = "80%";
                        img.style.height = "80%";

                    }
                });
            }

            

            if (nearEnd) { // Your cloneFilters function here, if needed
            }
        };

        filterContainer.addEventListener('scroll', handleScroll);

    }, [_cameraContext.cameraEnabled]);

    return (
        <div className="snapper-controls"
            style={
                {}

        }>
            <div className="filter-icons allow-scroll" id="filterContainer"
     
                ref={
                    props.filterContainerRef
            }>
                {

                _gameContext.companyData.assets.filter(asset => asset.assetType == 'filter').concat(_gameContext.globalAssets.filters.filter(asset => asset.assetType == "filter")).map((asset, index) => {
                    let thumb = asset.assetThumb;
                    let full = asset.assetLocation;
                    let blendAttr = asset.attributes ?. find((attr : CompanyDataAssetAttributesModel) => attr.attributeID === 'blend-mode');
                    let blend = SnapHelper.toBlendMode(blendAttr ?. attributeValue || '');
                    let showFilter = asset.attributes ?. find((attr : CompanyDataAssetAttributesModel) => attr.attributeID === 'show-filter');

                    if (showFilter) {
                        if (showFilter.attributeValue == 'false') {

                            full = "";
                        }
                    }


                    return <div className="filter-icon"
                        key={index}
                        onClick={
                            () => specificFilterTapped(index)
                    }>
                        <div className="filter-image"
                            data-blend={blend}
                            data-id={full}
                            style={
                                {backgroundImage: StyleHelper.format_css_url_without_asset(thumb)}
                        }></div>
                    </div>
            })
            } </div>
            <button className="snap-button"
                onClick={
                    props.snappedPicture
                }
                id="snapButton"></button>
        </div>
    )
}

export default CameraFilterChooser
