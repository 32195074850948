import React, { useEffect, useRef } from 'react'
import './CameraSnapResultViewer.scss';

interface IProps {
    onClear: () => void;
    picture: string | null;
}
function CameraSnapResultViewer(props: IProps) {
    const canvasRef = useRef<HTMLCanvasElement>(null);
  return (
    <div className='camera-snap-result-viewer'>
        <img src={props.picture!} />
    </div>
  )
}

export default CameraSnapResultViewer