import React from 'react'
import './CameraToolbar.scss'
import { Icons } from '../../../atoms/Icons'
interface IProps {
    onClose: () => void;
    onDone?: () => void;
}
function CameraToolbar(props: IProps) {
  return (
    <div className='camera-toolbar'>
        <div className='inside-container'>
            <div className='tooltip-left'>
                <div className='close-button' onClick={props.onClose}><Icons.Camera_Close /></div>
            </div>
            <div className='tooltip-right'>
                {props.onDone && <div className='done-button' onClick={props.onDone}><Icons.Camera_Check /></div>}
            </div>
        </div>
    </div>
  )
}

export default CameraToolbar