export interface SplitDateObject {
    month: string;
    day: number;
    year: number;
    ordinal: string;
    time: string;
    timezone: string;
    meridiem: string;
}

export default class DateHelper {
    static formatDateYearAndMonth(date: Date): string {
        //console.log("date: ", date);
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const month = monthNames[date.getMonth()]; // getMonth() returns a zero-based value (0 for January, 1 for February, etc.)
        const year = date.getFullYear();
      
        return `${month} ${year}`;
      }
    static getScorecardDate(date: Date): SplitDateObject {
      // Februray 24th, 2021
      let month = date.toLocaleString('default', { month: 'long' });
      let day = date.getDate();
      let year = date.getFullYear();

      const hours = date.getHours();
      const minutes = date.getMinutes();
      const meridiem = hours >= 12 ? 'PM' : 'AM';

      // create a variable for time and timezone
      let time = ((hours + 11) % 12 + 1) + ':' + (minutes < 10 ? '0' : '') + minutes;
     
      // I only want the timezone in this variable, just like EST
      const formatter = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' });
      const dateString = formatter.format(date);
      const matches = dateString.match(/\b([A-Z]{2,4})\b/); // Attempt to extract abbreviation
      const timezone = matches ? matches[1] : '';
      
      return {
        month: month,
        day: day,
        year: year,
        ordinal: DateHelper.getOrdinal(day),
        time: time,
        timezone: timezone,
        meridiem: meridiem
      }
    }
    static getOrdinal(n: number): string {
      let s = ["th", "st", "nd", "rd"],
        v = n % 100;
      return (s[(v - 20) % 10] || s[v] || s[0]);
    }
    static formatDateYearAndMonthFromString = (dateString: string) => {
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      let d = new Date(dateString);
      let month = monthNames[d.getMonth()]; // getMonth() returns a zero-based value (0 for January, 1 for February, etc.)
      let year = d.getFullYear();

      return `${month} ${year}`.toLowerCase();
  }
}