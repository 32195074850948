import React, {
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
    useState
} from 'react'
import './SnapPictureModal.scss'
import {Icons} from '../../atoms/Icons'
import {GameContext} from '../../../contexts/GameContext'
import StyleHelper from '../../../helpers/StyleHelper'
import SnapHelper, {BlendMode, GlobalCompositeOp} from '../../../helpers/SnapHelper'
import {CompanyDataAssetAttributesModel} from '../../../models/data/CompanyDataModel'
import CameraPreflight from './camera-preflight/CameraPreflight'
import {CameraContext} from '../../../contexts/CameraContext'
import CameraFeedContainer from './camera-feed-container/CameraFeedContainer'
import CameraFilterChooser from './camera-filter-chooser/CameraFilterChooser'
import CameraFilterViewer from './camera-filter-viewer/CameraFilterViewer'
import CameraSnapResultViewer from './camera-snap-result-viewer/CameraSnapResultViewer'
import CameraSaveSnapContainer from './camera-save-snap-container/CameraSaveSnapContainer'
import CameraSaveSnapToolbar from './camera-save-snap-toolbar/CameraToolbar'
import CameraToolbar from './camera-save-snap-toolbar/CameraToolbar'
import Webcam from "react-webcam";
enum SnapPictureModalState {
    SNAP_MODE,
    REVIEW_MODE
}


function SnapPictureModal() {
    const _cameraContext = useContext(CameraContext);
    const _gameContext = useContext(GameContext)
    const [modalState, setModalState] = useState < SnapPictureModalState > (SnapPictureModalState.SNAP_MODE);

    const videoRef = useRef < HTMLVideoElement > (null)
    const webcamRef = useRef<Webcam>(null);
    const [facingMode, setFacingMode] = useState < 'user' | 'environment' > ('environment');
    const [picture, setPicture] = useState < string | null > (null);

    const filterContainerRef = useRef < HTMLDivElement > (null);
    const [selectedFilter, setSelectedFilter] = React.useState < string | null > (null);
    const [selectedFilterBlendMode, setSelectedFilterBlendMode] = React.useState < BlendMode > ('normal');


    const handleCloseButton = () => {
        if (videoRef.current && videoRef.current.srcObject) {
            const stream = videoRef.current.srcObject as MediaStream;
            stream.getTracks().forEach(track => track.stop());
            videoRef.current.srcObject = null;
        }

        _gameContext.updateSnapPictureEnabled(false);
    }
    const handleUpdateSelectedFilter = (filter: string | null) => {
        setSelectedFilter(old => filter);
       
    }
    const handleUpdateSelectedBlendMode = (blendMode: BlendMode) => {
      
        setSelectedFilterBlendMode(old => blendMode);
    }



    const snappedPicture = (base64ImageString: string) => {
        const canvas = document.createElement('canvas');
        const image = new Image();
        image.src = base64ImageString;
        let facing = facingMode;
        //console.log("~~~FACING MODE", facing);
    
        image.onload = () => {
            const elementWidth = image.width;
            const elementHeight = image.height;
    
            // Assuming dimensions can be calculated similarly for the image
            const dimensions = SnapHelper.calculateCopyDimensions(image, elementWidth, elementHeight);
    
            const ctx = SnapHelper.configureCanvas(canvas, elementWidth, elementHeight, facing, selectedFilterBlendMode);
    
            // Draw base64 image to canvas instead of video
            //console.log("JON FACING MODE", facing)
            SnapHelper.drawImageToCanvas(ctx, image, dimensions, elementWidth, elementHeight, facing);
    
            if (selectedFilter) {
      
                SnapHelper.applyFilterToCanvas(ctx, selectedFilter, SnapHelper.handleOverlayImageLoadError(ctx, setPicture), () => SnapHelper.handleOverlayImageLoadError(ctx, setPicture)());
            } else {
               // //console.log("FILTER WAS NO SELECTED");
                SnapHelper.generateAndSetDataUrl(canvas, setPicture);
            }
        };
    }


    useEffect(() => {
        if(picture)
        {
            setModalState(SnapPictureModalState.REVIEW_MODE);
        }
        else
        {
            setModalState(SnapPictureModalState.SNAP_MODE);
        }
    }, [picture]);

    useEffect(() => {
      console.warn("facing mode changed", facingMode);
    }, [facingMode]);
    
    const handleOnClearEvent = () => {
        setPicture(null);
    }

    const handleClearCurrentPictureEvent = () => {
        setPicture(null);
    }

    const handleSavePictureEvent = () => {
        let payloadFormData = new FormData()
    payloadFormData.append('CustomerKey', _gameContext.companyData.customerID)
    payloadFormData.append('GameID', _gameContext.gameID)
    payloadFormData.append('Filename', 'asd')
    payloadFormData.append('Image', SnapHelper.dataURLToBlob(picture!))

    let pictureID = SnapHelper.generateRandomString(10)
    let newPicture = SnapHelper.CreateNewPicture()
    newPicture.id = pictureID
    newPicture.processed = false

    if (picture != '') {
      SnapHelper.generateSmallThumb(picture!)
        .then(smallThumb => {
          newPicture.tmp_thumb = smallThumb
          _gameContext.addPicture(newPicture)
          //console.log('smallThumb', smallThumb)
        })
        .catch(err => {
          return
        });

      //console.log('firing')
      let response = fetch(
        `${process.env.REACT_APP_API_URL}/Image/SaveImage`,
        {
          method: 'POST',
          body: payloadFormData
        }
      )
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            //console.log(data.filename)
            newPicture.filename = data.filename
            newPicture.processed = true
            _gameContext.updatePicture(newPicture)
          }
        })
        .catch(err => {
          newPicture.failure = true
          _gameContext.updatePicture(newPicture);
          //console.log("error", err)
        })
    }


    if (videoRef.current && videoRef.current.srcObject) {
      let tracks = (videoRef.current.srcObject as MediaStream).getTracks();
      tracks.forEach(track => track.stop());
  }
    _gameContext.updateSnapPictureEnabled(false)
    }

    const handleSnappedPictureEvent = () => {
        capture();
    }
    const capture = React.useCallback(
        () => {
          if(webcamRef.current)
          {
            const imageSrc = webcamRef.current.getScreenshot();
            snappedPicture(imageSrc!);
          }
        },
        [webcamRef, selectedFilter]
      );

      const updateFacingMode = (thisfacingMode: 'user' | 'environment') => {
        //console.log("triggering update", thisfacingMode)
        setFacingMode(old => thisfacingMode);
      }
    return (
        <div className='snap-picture-modal'>
             <div className="camera-debug-info">
       
        <div>selectedFilter: {selectedFilter}</div>

      </div>
            {
            ! _cameraContext.cameraEnabled ? (
                <CameraPreflight/>) : (
                <>
<CameraFeedContainer onClose={handleCloseButton}
                        videoRef={webcamRef}
                        facingMode={facingMode}
                        updateFacingMode={updateFacingMode}/> 
                        {
                    modalState === SnapPictureModalState.SNAP_MODE && <>
                    
                        <CameraFilterChooser 
                            filterContainerRef={filterContainerRef}
                            updateSelectedFilter={handleUpdateSelectedFilter}
                            updateBlendMode={handleUpdateSelectedBlendMode}
                            snappedPicture={handleSnappedPictureEvent}
                        />
                        <CameraToolbar onClose={handleCloseButton} />
                        <CameraFilterViewer selectedFilter={selectedFilter} blendMode={selectedFilterBlendMode} />
                       
                    </>
                
                } 
                {
                    modalState === SnapPictureModalState.REVIEW_MODE && 
                    <>
                        <CameraSnapResultViewer picture={picture} onClear={handleOnClearEvent} />
                        <CameraToolbar onClose={handleClearCurrentPictureEvent} onDone={handleSavePictureEvent} />
                        <CameraSaveSnapContainer />
                    </> 
                }
                
                
                </>
            )
        } </div>
    )
}

export default SnapPictureModal
