import CompanyGeoFenceLocation from "../models/data/CompanyGeoFenceLocationModel";
import CustomerSEOInfo from "../models/data/CustomerSEOInfoModel";


const blobPrefix = (filename: string) => {
    return `https://ppgstorageaccount.blob.core.windows.net/seo-images/${filename}`
}
const resultBlobPrefix = (customerID: string, gameID: string) => {
    return `https://ppgstorageaccount.blob.core.windows.net/shareables/${customerID}-${gameID}.jpg`
}

const deactivatedAccounts: string[] = [

]

export const customerSEOInfo : CustomerSEOInfo[] = [
    {
        id: "castle-golf",
        name: "Castle Golf",
        metaTitle: "Castle Golf | Digital Scorecard by PuttPuttGO",
        metaDescription: "Castle Golf is an 18 hole course located in Fort Myers, FL. Scan the PuttPuttGO QR code to keep score, track stats, compete with friends and more!",
        metaImage: blobPrefix("meta_castle-golf.jpg"),
        address: "7400 Gladiolus Dr, Fort Myers, FL 33908",
        phone: "(239) 489-1999",
        website: "http://www.castle-golf.com/",
        facebook: "https://www.facebook.com/CastleGolf/",
        resultsTitle: "🏰 We've bested Castle Golf! 🏆",
        resultsDescription: "Check out our scores!"
    },
    {
        id: "demo",
        name: "Demo Course",
        metaTitle: "Demo Course | Digital Scorecard by PuttPuttGO",
        metaDescription: "Demo Course is an 18 hole course located in Fort Myers, FL. Scan the PuttPuttGO QR code to keep score, track stats, compete with friends and more!",
        metaImage: blobPrefix("meta_demo.jpg"),
        address: "123 Fake St, Fort Myers, FL 33901",
        phone: "(239) 555-5555",
        website: "http://www.puttputtgo.net/",
        resultsTitle: "🏰 We've completed the Demo Course! 🏆",
        resultsDescription: "Check out our scores!",
        facebook: '',
    }
];

const geoFenceData : CompanyGeoFenceLocation[] = [
    { latLng: { lat: 26.523364, lng: -81.878188}, radius: 0.3, companyID: "castle-golf" },
    { latLng: { lat: 26.578641061150, lng: -81.884679037693}, radius: 3, companyID: "ppg-zone"}
]

export default class CompanyHelper {
    static ListOfBusinesses = [
        "default",
        "demo",
        "ppg",
        "castle-golf",
        "bonanza-golf",
        "smugglers-cove",
        "jungle-golf",
        "pink-paradise",
        "gator-mikes"
        
    ];
    static DoesCompanyExist(companyName: string): boolean {
        return this.ListOfBusinesses.includes(companyName);
    }
    static GetCompanySEOInfo(companyName: string): CustomerSEOInfo | null {
        const company = customerSEOInfo.find(x => x.id === companyName);
        if(company) {
            return company;
        }
        return null;
    }
    static GetAllGeoFenceData(companyID: string): CompanyGeoFenceLocation[] | null {
        const company = geoFenceData.filter(x => x.companyID === companyID || x.companyID === "ppg-zone");
        if(company) {
            return company;
        }
        return null;
    }
    static IsDeactivated(customerID: string) : boolean {
        return deactivatedAccounts.includes(customerID);
    }
}