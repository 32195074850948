import SnapModel from "../models/snaps/SnapModel";

export type BlendMode = 'normal' | 'multiply' | 'screen' | 'overlay' | 'darken' | 'lighten' | 'color-dodge' | 'color-burn' | 'hard-light' | 'soft-light' | 'difference' | 'exclusion' | 'hue' | 'saturation' | 'color' | 'luminosity';

export type GlobalCompositeOp = "source-over" | "source-in" | "source-out" | "source-atop" | "destination-over" | "destination-in" | "destination-out" | "destination-atop" | "lighter" | "copy" | "xor" | "multiply" | "screen" | "overlay" | "darken" | "lighten" | "color-dodge" | "color-burn" | "hard-light" | "soft-light" | "difference" | "exclusion" | "hue" | "saturation" | "color" | "luminosity";

interface Dimensions {
    copyWidth: number;
    copyHeight: number;
    xOffset: number;
    yOffset: number;
  }

export default class SnapHelper {
    static CreateNewPicture(): SnapModel {
        return {id: '', filename: '', processed: false, failure: false}
    }
    static CreateNewPictureWithValues(id : string, filename : string, processed : boolean, type?: number): SnapModel {
        return {id: id, filename: filename, processed: processed, failure: false, type: type}
    }
    static generateRandomString(length : number): string {
        let allowedChars = 'abcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += allowedChars.charAt(Math.floor(Math.random() * allowedChars.length));
        }
        return result;
    }
    static dataURLToBlob(dataURL : String): Blob { // Split the base64 string in data and contentType
        const parts = dataURL.split(';base64,');
        const byteCharacters = atob(parts[1]);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {
            type: parts[0].split(':')[1]
        });
    }
    static generateSmallThumb = (picture : string): Promise < string > => {
        return new Promise((resolve, reject) => { // create a canvas element
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            const image = new Image()
            image.src = picture
            image.onload = function () { // Determine the aspect ratio
                const aspectRatio = image.height / image.width

                // Calculate new dimensions
                const targetWidth = 40
                const targetHeight = targetWidth * aspectRatio

                // Set canvas dimensions
                canvas.width = targetWidth
                canvas.height = targetHeight

                // Draw source image into the off-screen canvas:
                ctx !.drawImage(image, 0, 0, targetWidth, targetHeight)

                // Encode image to data-uri with base64 version of compressed image
                let smallThumb = canvas.toDataURL('image/jpeg', 0.5)
                resolve(smallThumb)
            }
            image.onerror = function () {
                reject(new Error('Failed to load the image.'))
            }
        })
    }

    static toBlendMode(value : string): BlendMode {
        const blendModes: BlendMode[] = [
            'normal',
            'multiply',
            'screen',
            'overlay',
            'darken',
            'lighten',
            'color-dodge',
            'color-burn',
            'hard-light',
            'soft-light',
            'difference',
            'exclusion',
            'hue',
            'saturation',
            'color',
            'luminosity'
        ];
        if (blendModes.includes(value as BlendMode)) {
            return value as BlendMode;
        }
        return 'normal'; // or default to 'normal', depending on your requirement
    }

    static blendModeToCompositeOp(blendMode : BlendMode): GlobalCompositeOp {
        const map: Record<BlendMode, GlobalCompositeOp> = {
            'normal': 'source-over',
            'multiply': 'multiply',
            'screen': 'screen',
            'overlay': 'overlay',
            'darken': 'darken',
            'lighten': 'lighten',
            'color-dodge': 'color-dodge',
            'color-burn': 'color-burn',
            'hard-light': 'hard-light',
            'soft-light': 'soft-light',
            'difference': 'difference',
            'exclusion': 'exclusion',
            'hue': 'hue', // Not a direct match in canvas
            'saturation': 'saturation', // Not a direct match in canvas
            'color': 'color', // Not a direct match in canvas
            'luminosity': 'luminosity' // Not a direct match in canvas
        };

        return map[blendMode] || 'source-over'; // default to 'source-over' if not found
    }


    // FOR THE MAIN SNAP PICTURE MODAL  
    static calculateCopyDimensions = (imageElement: HTMLImageElement, elementWidth: number, elementHeight: number): Dimensions => {
      const imageWidth = imageElement.width;
      const imageHeight = imageElement.height;
      const imageAspect = imageWidth / imageHeight;
      const elementAspect = elementWidth / elementHeight;
  
      let copyWidth: number, copyHeight: number, xOffset: number, yOffset: number;
  
      if (imageAspect > elementAspect) {
          copyHeight = imageHeight;
          copyWidth = elementWidth * (imageHeight / elementHeight);
          xOffset = (imageWidth - copyWidth) / 2;
          yOffset = 0;
      } else {
          copyWidth = imageWidth;
          copyHeight = elementHeight * (imageWidth / elementWidth);
          xOffset = 0;
          yOffset = (imageHeight - copyHeight) / 2;
      }
  
      return { copyWidth, copyHeight, xOffset, yOffset };
  };
  

      static configureCanvas = (canvas: HTMLCanvasElement, elementWidth: number, elementHeight: number, facingMode: string, blendMode: string): CanvasRenderingContext2D => {
        canvas.width = elementWidth;
        canvas.height = elementHeight;
        const ctx = canvas.getContext('2d');
        if (!ctx) throw new Error("Could not get canvas context");
      
        ctx.clearRect(0, 0, elementWidth, elementHeight);
      
        if (facingMode === 'user') {
          ctx.scale(-1, 1);
        }
      
        ctx.globalCompositeOperation = this.blendModeToCompositeOp(this.toBlendMode(blendMode));
        return ctx;
      };

      static drawImageToCanvas = (
        ctx: CanvasRenderingContext2D, 
        imageElement: HTMLImageElement, 
        dimensions: Dimensions, 
        elementWidth: number, 
        elementHeight: number, 
        facingMode: string
    ): void => {
        const destX = facingMode === 'user' ? -elementWidth : 0;
    
        ctx.drawImage(
            imageElement,
            dimensions.xOffset,
            dimensions.yOffset,
            dimensions.copyWidth,
            dimensions.copyHeight,
            destX,
            0,
            elementWidth,
            elementHeight
        );
    
        if (facingMode === 'user') {
            ctx.scale(-1, 1);
            ctx.translate(-elementWidth, 0);
        }
    };
    

      static applyFilterToCanvas = (ctx: CanvasRenderingContext2D, filterSrc: string, onSuccess: (overlayImage: HTMLImageElement) => void, onError: () => void): void => {
        let overlayImage = new Image();
        overlayImage.src = filterSrc;
        overlayImage.onload = () => onSuccess(overlayImage);
        overlayImage.onerror = onError;
      };

      static generateAndSetDataUrl = (canvas: HTMLCanvasElement, setPicture: (dataURL: string) => void): void => {
        const dataURL = canvas.toDataURL('image/jpeg');
        setPicture(dataURL);
      };

      static handleOverlayImageLoadError = (ctx: CanvasRenderingContext2D, setPicture: (dataURL: string) => void) => (overlayImage: HTMLImageElement | null = null): void => {
        if (overlayImage) {
          ctx.drawImage(overlayImage, 0, 0, window.innerWidth, window.innerHeight);
        }
        this.generateAndSetDataUrl(ctx.canvas, setPicture);
      };
      

}
