import React, {useContext, useEffect, useState} from 'react'
import './CameraPreflight.scss'
import {CameraContext} from '../../../../contexts/CameraContext';
import {GameContext} from '../../../../contexts/GameContext';

type CameraPermissionState = 'granted' | 'denied' | 'prompt';
interface IProps {
    onEnabled: () => void;
}
function CameraPreflight() {
    const _cameraContext = useContext(CameraContext);
    const _gameContext = useContext(GameContext);

    const [cameraError, setCameraError] = useState < string | null > (null);

    async function checkCameraPermission(): Promise < void > {
        try {
            const permissionStatus = await navigator.permissions.query({name: 'camera' as PermissionDescriptor['name']});
            if (permissionStatus.state === 'granted') {
                _cameraContext.updateCameraEnabled(true);
            }

            permissionStatus.onchange = () => {
                if (permissionStatus.state === 'granted') {
                    _cameraContext.updateCameraEnabled(true);
                }
            };
        } catch (error) {
            _cameraContext.updateCameraEnabled(false);
        }
    }

    async function toggleCameraPermission(): Promise < void > {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({video: true});
            stream.getTracks().forEach(track => track.stop());

            _cameraContext.updateCameraEnabled(true);
           
            setCameraError(null); // Reset the error if we get here

            //console.log('Camera access granted.');
        } catch (error : unknown) {
            if (error instanceof Error) {
                if (error.name === 'NotAllowedError') {
                    setCameraError('Please enable access to your camera');
                } else {
                    setCameraError(`Error accessing the camera:${error}`);
                }
            } else {
                setCameraError(`An unexpected error occurred:${error}`);
            }
        }
    }


    useEffect(() => {
        checkCameraPermission();
    }, []);

    const handleBackButton = () => {
        _gameContext.updateSnapPictureEnabled(false);
    }
    return (
        <div className='camera-preflight-wrapper'>
            <div className='preflight-enable-wrap'>
                <div className='large-text'>Please enable access to your camera</div>
                {
                cameraError && <div className='error-text'>
                    {cameraError}</div>
            }
                <div className='enable-camera-button'
                    onClick={toggleCameraPermission}>enable camera</div>
                <div className='back-button'
                    onClick={handleBackButton}>back</div>
            </div>
        </div>
    )
}

export default CameraPreflight
