import React, { useContext, useEffect, useState } from "react";
import "./BestWorseHoles.scss";
import { GameContext } from "../../../../contexts/GameContext";
import { BestWorstHole } from "../../../../models/results/ResultModels";
import StyleHelper from "../../../../helpers/StyleHelper";
import { PlayerContext, formatRGBToCSS } from "../../../../contexts/PlayerContext";
import { CourseContext } from "../../../../contexts/CourseContext";
import { ScoreContext } from "../../../../contexts/ScoreContext";
import PlayerModel from "../../../../models/player/PlayerModel";

interface Props {
}

function BestWorseHoles(props: Props) {
    const _gameContext = useContext(GameContext);
    const _courseContext = useContext(CourseContext);
    const _playerContext = useContext(PlayerContext);
    const _scoreContext = useContext(ScoreContext);


    const [hideDropdown, setHideDropdown] = useState<boolean>(true);

    const [dropdownActive, setDropdownActive] = useState<boolean>(false);
    const [activeBestWorst, setActiveBestWorst] = useState<PlayerModel | null>(null);

    const [bestWorstHole, setBestWorstHole] = useState<BestWorstHole | null>(
        {
            bestHole: 0,
            bestPar: 0,
            bestAvg: 0,
            worstHole: 0,
            worstPar: 0,
            worstAvg: 0,
        }
      );

    useEffect(() => {
        if (_gameContext.preloadedLocalStorage) {
            setBestWorstHole(findBestWorstHole(null));
        }
    }, [_gameContext.preloadedLocalStorage]);

    const handlePlayerPerformanceClicked = (player: PlayerModel | null) => {
        setActiveBestWorst(player);
        setBestWorstHole(findBestWorstHole(player));
        setDropdownActive(false);
    };
    const handleDropdownClicked = () => {
        setDropdownActive(!dropdownActive);
    };


    const findBestWorstHole = (player: PlayerModel | null): BestWorstHole => {
        const holes = _courseContext.getCurrentCourse().holes;
        const players = _playerContext.getAllPlayers();
      
        // Initialize variables to store overall and individual best/worst holes and scores
        let bestHole = { number: 0, par: 0, avg: Infinity };
        let worstHole = { number: 0, par: 0, avg: -Infinity };
      
        // Calculate average scores for each hole across all players
        const holeAverages = holes.map(hole => {
          const scores = players.map(player => _scoreContext.getScoreByHoleAndPlayer(hole.number, player.id)).filter(score => score > 0);
          const average = scores.length > 0 ? scores.reduce((a, b) => a + b, 0) / scores.length : 0;
          return { hole: hole.number, par: hole.par, avg: average };
        });
      
        if (player) {
          // Find the player's best and worst hole based on their performance relative to the group averages
          return getPlayerBestWorstHole(player);
        } else {
          // Find overall best and worst holes based on average scores
          holeAverages.forEach(hole => {
            if (hole.avg < bestHole.avg) {
              bestHole = { number: hole.hole, par: hole.par, avg: hole.avg };
            }
            if (hole.avg > worstHole.avg) {
              worstHole = { number: hole.hole, par: hole.par, avg: hole.avg };
            }
          });
        }
      
        // Return the best and worst hole information
        return {
          bestHole: bestHole.number,
          bestPar: bestHole.par,
          bestAvg: bestHole.avg,
          worstHole: worstHole.number,
          worstPar: worstHole.par,
          worstAvg: worstHole.avg,
        };
      };

      const getPlayerBestWorstHole = (player: PlayerModel): BestWorstHole => {
        // steps:
        // 1. find the best score that the player has, if he has multiple of the same score,
        // add it to an array of hole numbers
        // 2. find all other players scores for that hole and figure out which one is the best
        // 3. repeat for worst hole
        const holes = _courseContext.getCurrentCourse().holes;
        const players = _playerContext.getAllPlayers();
        
        let bestHole = { number: 0, par: 0, avg: Infinity };
        let worstHole = { number: 0, par: 0, avg: -Infinity };

        const playerScores = holes.map(hole => {
            return { hole: hole.number, score: _scoreContext.getScoreByHoleAndPlayer(hole.number, player.id) };
        });

        playerScores.forEach(hole => {
            if (hole.score < bestHole.avg) {
                bestHole = { number: hole.hole, par: holes.find(h => h.number === hole.hole)!.par, avg: hole.score };
            }
            if (hole.score > worstHole.avg) {
                worstHole = { number: hole.hole, par: holes.find(h => h.number === hole.hole)!.par, avg: hole.score };
            }
        });

        console.log({
            bestHole: bestHole.number,
            bestPar: bestHole.par,
            bestAvg: bestHole.avg,
            worstHole: worstHole.number,
            worstPar: worstHole.par,
            worstAvg: worstHole.avg,
        })
        return {
            bestHole: bestHole.number,
            bestPar: bestHole.par,
            bestAvg: bestHole.avg,
            worstHole: worstHole.number,
            worstPar: worstHole.par,
            worstAvg: worstHole.avg,
        };

      }
      
      
      

    return (
        <div className="best-worst-hole-wrap">
            

        {hideDropdown == false && <>
        <div className="best-worst-chooser-wrap">
            <div className='best-worst-dropdown'>
                                <div className='selected-option' onClick={handleDropdownClicked}>
                                    <div className='display'>
                                        <div className='icon'></div>
                                        <div className='text'>{activeBestWorst ? activeBestWorst.name : _gameContext.getPlainTextByID("results:performance-all")}</div>
                                    </div>
                                    <div className='close'>
                                        <div className='icon' onClick={handleDropdownClicked} style={{
                                            backgroundImage: StyleHelper.format_css_url(_gameContext.getAssetByID('performance-caret')),
                                            transform: dropdownActive ? 'rotate(180deg)' : 'rotate(0deg)'
                                        }}></div>
                                    </div>
                                </div>
                                <div className='list' style={{
                                    display: dropdownActive ? 'block' : 'none'
                                }}>
                                    
                                    {activeBestWorst && <div className='item' onClick={() => handlePlayerPerformanceClicked(null)}>
                                        <div className='icon'></div>
                                        <div className='text'>{_gameContext.getPlainTextByID("results:performance-all")}</div>
                                    </div>
}

                                    {_playerContext.getAllPlayers().map((player, index) => {
                                        if(activeBestWorst) {
                                            if(activeBestWorst.id === player.id) {
                                                return null;
                                            }
                                        }
                                        return <div key={index} className='item' onClick={() => handlePlayerPerformanceClicked(player)}>
                                        <div className='icon'
                                        style={
                                            {
                                                backgroundImage: StyleHelper.format_css_url(_gameContext.getAssetByID('gameplay-player-ball-frame')),
                                                backgroundColor: formatRGBToCSS(player.color !, 1)
                                            }
                                    }></div>
                              
                                        <div className='text'>{player.name}</div>
                                    </div>
                                    })}
                                    
                                </div>
                            </div>
                            </div>
                            </>}

            <div className="best-worst-main">
                <div className="left">
                    <div className="inside-content">
                        <div
                            className="title"
                            dangerouslySetInnerHTML={_gameContext.getTextByID(
                                "results:best-hole"
                            )}
                        ></div>
                        <div className="large-number best">
                            {bestWorstHole?.bestHole}
                        </div>
                        <div className="par">
                            { activeBestWorst == null ? `par ${bestWorstHole?.bestPar}` : `score:  ${bestWorstHole?.bestPar}`}
                        </div>
                        <div className="group">
                            {_gameContext.getPlainTextByID(
                                "results:group-average"
                            )}
                            :{" "}
                            {bestWorstHole!.bestAvg % 1 === 0
                                ? bestWorstHole?.bestAvg
                                : Math.round(bestWorstHole!.bestAvg)}
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="inside-content">
                        <div
                            className="title"
                            dangerouslySetInnerHTML={_gameContext.getTextByID(
                                "results:worst-hole"
                            )}
                        ></div>
                        <div className="large-number worst">
                            {bestWorstHole!.worstHole}
                        </div>
                        <div className="par">
                            par {bestWorstHole!.worstPar}
                        </div>
                        <div className="group">
                            {_gameContext.getPlainTextByID(
                                "results:group-average"
                            )}
                            :{" "}
                            {bestWorstHole!.worstAvg % 1 === 0
                                ? bestWorstHole!.worstAvg
                                : Math.round(bestWorstHole!.worstAvg)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BestWorseHoles;
