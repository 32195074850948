import React, { useContext } from "react";
import "./ProfileHeader.scss";
import { SignUpRegisterContext } from "../../../../contexts/SignUpRegisterContext";
import { GameContext } from "../../../../contexts/GameContext";
import DateHelper from "../../../../helpers/DateHelper";
import { ProfileScreenTabs } from "../../../pages/profile-screen/ProfileScreen";
interface Props{
    setShowProfilePictureModal: (boolean: boolean) => void;
    setProfileImageFile: (file: File) => void;
    currentScreenState: ProfileScreenTabs;
}
function ProfileHeader(props: Props) {
    const _gameContext = useContext(GameContext);
    const _signupContext = useContext(SignUpRegisterContext);
    const fileUploadRef = React.useRef<HTMLInputElement>(null);
    
    const fallbackProfilePicture = () => {
        if(_signupContext.signedInUser?.user.ProfileImage?.includes(process.env.REACT_APP_STORAGE_ACCOUNT_URL!))
        {
            return _signupContext.signedInUser?.user.ProfileImage;
        }
        return `${process.env.REACT_APP_STORAGE_ACCOUNT_URL}/profile-pics/${_signupContext.signedInUser?.user.ProfileImage}`;
    }
    const triggerFileUpload = () => {
      
        if(fileUploadRef.current)
        {
            fileUploadRef.current.click();
        }
    }
    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.files && e.target.files.length > 0)
        {
            props.setShowProfilePictureModal(true);
            props.setProfileImageFile(e.target.files[0]);
        }
    }

    return (
        <div className="profile-detail-structure-wrap">
            <div className="profile-details-wrap">
                <div className="profile-image-wrap">
                    <div className="profile-image" onClick={triggerFileUpload}>
                        {_signupContext.signedInUser?.user.ProfileImage ? (
                            <div className="real-profile-picture">
                                <img
                                    src={_gameContext.getInMemoryAssetById(
                                        `${_signupContext.signedInUser.user.UserKey}-profile-pic`,
                                        fallbackProfilePicture()
                                    )}
                                />
                            </div>
                        ) : (
                            <div className="upload-picture-button">
                                upload
                                <br />
                                profile picture
                            </div>
                        )}

                        <input
                            type="file"
                            ref={fileUploadRef}
                            onChange={handleFileUpload}
                        />
                    </div>
                </div>
                <div className="profile-description-wrap">
                    <div className="username">
                        {_signupContext.signedInUser?.user.Username}
                    </div>
                    <div className="joined-at">
                        joined{" "}
                        {DateHelper.formatDateYearAndMonthFromString(
                            _signupContext.signedInUser!.user.CreatedDate!.toString()
                        )}
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default ProfileHeader;
