import React, { useContext } from "react";
import "./Feedback.scss";
import { GameContext } from "../../../../contexts/GameContext";
import { Icons } from "../../../atoms/Icons";

function Feedback() {
  const _gameContext = useContext(GameContext);
  const [selectedStars, setSelectedStars] = React.useState<number>(5);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [thankYou, setThankYou] = React.useState<boolean>(false);
  const [comments, setComments] = React.useState<string>("");
  const [sent, setSent] = React.useState<boolean>(false);
  const [lastTimeSentFeedback, setLastTimeSentFeedback] = React.useState<number>(Date.now());

  const saveFeedback = () => {
    if (Date.now() - lastTimeSentFeedback < 10000) {
      alert("Please wait a moment before sending another feedback");
      return;
    }
    setLastTimeSentFeedback(Date.now());
    if (comments.length <= 0) {
      //alert("Please enter a comment");
      //return;
    }
    setSaving(true);

    let payload = {
      gameID: _gameContext.gameID,
      stars: selectedStars,
      comments: comments,
    };
    fetch(process.env.REACT_APP_API_URL + "/game/SaveFeedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
       
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(() => {
        setThankYou(true);
        setComments("");
        setSelectedStars(3);
        setTimeout(() => {
          setThankYou(false);
          setSaving(false);
        }, 2000);
      });
  };

  return  ( 
    <div className="feedback-container">
      {saving && (
        <div className="feedback-saving">
          {thankYou && <div className="thank-you">thank you!</div>}
          {!thankYou && (
            <>
              <div className="saving-text">saving feedback</div>
              <div className="saving-spinner">
                <Icons.Gallery_Spinner />
              </div>
            </>
          )}
        </div>
      )}
      <div className="title">share your feedback</div>
      <div className="description">
        Your insights are valuable to us as we enhance our platform.
      </div>
      <div className="stars-wrap">
        {[1, 2, 3, 4, 5].map((star) => {
          return (
            <div
              key={star}
              className={`star ${star <= selectedStars ? "selected" : ""}`}
              onClick={() => setSelectedStars(star)}
            >
              <Icons.Star />
            </div>
          );
        })}
      </div>
      <div className="comments-wrap">
        <textarea
          placeholder="Comments"
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
      </div>
      <div className="submit-wrap" onClick={saveFeedback}>
        <span>submit</span>
        <img
          src={_gameContext.getAssetByID("continue-game-button")?.assetLocation}
        />
      </div>
    </div>

    );
    

}

export default Feedback;
